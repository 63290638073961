.instagram-container {
    margin: 48px 3 * $home-page-grid;
    @media #{$large-up} {
        margin: 180px 3 * $home-page-grid;
    }
    @media #{$medium-down} {
        margin: 48px 0 80px 0;
    }
    overflow: hidden;

    .swiper-container-instagram {
        @media #{$medium-down} {
            margin-left: -40vw;
        }
    }

    .media-item {
        display: block;
        position: relative;

        @media #{$large-up} {
            width: 25vw;
            height: 25vw;
            margin-left: 20px;
        }

        img.insta-media {
            width: 100%;
            height: inherit;
            object-fit: cover;
        }

        .insta-media-play-icon {
            position: absolute;
            top: 15px;
            right: 15px;
            @media #{$large-up} {
                top: 20px;
                right: 20px;
            }
        }
    }

    .row {
        justify-content: start;

        @media #{$large-up} {
            justify-content: flex-end;
        }
    }

    .media-trio-left {
        height: auto;
        margin-bottom: 40px;

        @media #{$large-up} {
            height: calc(25vw + 20px);
            margin-bottom: 0;
        }

        .media-wrapper {
            display: flex;
            position: absolute;
            left: -20vw;
            @media #{$medium-down} {
                display: none;
            }
        }
    }

    .media-trio-right {
        height: calc(35vw + 20px);

        @media #{$large-up} {
            height: calc(25vw + 20px);
        }

        .media-wrapper {
            display: flex;
            position: absolute;
            right: -3vw;
            @media #{$large-up} {
                right: auto;
                left: calc(30vw + 40px);
            }
        }
    }

    .follow-us {
        padding-bottom: 30px;
        @media #{$large-up} {
            padding-bottom: 0;
        }
        @media #{$medium-down} {
            margin: 48px 3 * $home-page-grid 0 3 * $home-page-grid;
            padding-bottom: 0;
        }
        h4 {
            font-size: 30px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.2;
            letter-spacing: 0;
            text-align: left;
        }
        p {
            font-style: normal;
            font-weight: 400;
            line-height: 1.3;
            letter-spacing: 0;
            text-align: left;
        }

        @media #{$large-up} {
            width: 30vw;
            display: flex;
            flex-flow: column;
            justify-content: center;

            h4 {
                font-size: 3vw;
            }
        }

        a {
            color: color('Yellow');
        }

        @import '../tools/_animations.scss';
        @include animated-a('a', true);
    }
}
