.no-padding-bottom {
    padding-bottom: 0 !important;
}

.width-100 {
    width: 100% !important;
}

.align-right {
    justify-content: flex-end;
    text-align: right;
}

@media #{$medium-down} {
    .medium-full {
        width: 100%;
    }
}

.desktop--interactive {
    cursor: pointer;
}

@keyframes bounce-right {
    from {
        left: 0;
    }

    to {
        left: 0.3em;
    }
}

.readmore--interactive {
    @extend .desktop--interactive;
    &:hover {
        svg {
            position: relative;
            animation-duration: 0.3s;
            animation-name: bounce-right;
            animation-direction: alternate;
            animation-iteration-count: 4;
        }
    }
}

%cta-block--interactive {
    &:hover {
        color: #1795D7;
    }
    &__link {
        position: relative;
    }
}

%cta-block__background--interactive {
    background-size: 208% auto;
    background-repeat: no-repeat;
    background-position: 100% 0;
    transition: background-position 0.2s ease-in-out;
    &:hover {
        background-position: 0 0;
        transition-duration: 0.4s;
    }
}

%cta-block__background--rinteractive {
    background-position: 100% 0;
    @extend %cta-block__background--interactive;
}
