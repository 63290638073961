%disabled {
    opacity: 0.3;
    background-image: none;
}

$news-on-homepage__image-height: 234px; //Arbitrary number pulled from design
$news-on-homepage__image-height--desk: 278px; //Arbitrary number pulled from design
$news-on-homepage__content-height: 6em; //Min-height due to number of items and whitespace

.news-on-homepage {
    // Create slightly more blue than the 'content' of the news-items
    // @see `.swiper-container-news` for the precise alignment
    box-shadow: inset 0 calc((-#{$news-on-homepage__image-height} - #{$news-on-homepage__content-height})) 0
        $color-primary;
    background-color: $color-primary--alt;
    margin-top: grid(1);
    overflow: hidden;
    @media #{$medium-down} {
        padding-left: 36px;
        padding-right: 36px;
    }

    .swiper-slide {
        height: auto;
    }

    &__container {
        display: flex;
        justify-content: space-between;
        margin-bottom: grid(0.5);
        @media #{$medium-down} {
            flex-direction: column;
            margin-bottom: 20px;
        }
        @media #{$hd-up} {
            margin-bottom: 111px;
        }
    }
    &__buttons {
        display: flex;
        flex-direction: row;
        padding: 0 66px;
        @media #{$medium-down} {
            padding: 0;
            margin-top: 30px;
        }

        @media #{$xlarge-up} {
            padding: 0 80px;
        }
        .news-on-homepage-prev {
            outline: none;
            @extend %swiper__nav-button;
            transform: rotate(-180deg);
            &.swiper-button-disabled {
                @extend %disabled;
            }
        }
        .news-on-homepage-next {
            outline: none;
            @extend %swiper__nav-button;
            &.swiper-button-disabled {
                @extend %disabled;
            }
        }
    }
    &__title {
        font-size: 30px;
        line-height: 1.2;
        font-weight: 400;
        @include respond-to('desktop') {
            font-size: 60px;
            line-height: 1.1;
        }
        @media #{$hd-up} {
            font-size: 78px;
        }
    }
    &__description {
        margin-top: 1em;
        font-size: 18px;
        line-height: 1.3;
        width: 40%;
        @media #{$medium-down} {
            flex-direction: column;
            width: 100%;
        }
        @media #{$hd-up} {
            font-size: 24px;
        }
    }
    &__news-list {
        background: $color-primary;
        box-shadow: inset 0 184px 0 $color-primary--alt;
        max-width: 100%;
        overflow-x: auto;
        overflow-y: visible;
        white-space: nowrap;
        padding-left: 2 * $home-page-grid;
        margin-left: 0;
        margin-top: 4.5 * $home-page-grid;
        vertical-align: top;
        display: flex;
        flex-wrap: nowrap;

        @include respond-to('desktop') {
            padding-left: 24 * $home-page-grid;
        }
    }
}
.news-on-homepage-item {
    @extend %cta-block--interactive;
    text-decoration: none;
    flex-grow: 1;
    flex-shrink: 0;
    min-height: 416px !important;
    white-space: normal;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    @include respond-to('desktop') {
        min-height: 400px !important;
    }
    @media #{$medium-down} {
        min-height: 360px !important;
    }

    &__background {
        width: 100%;
        background-color: white;
        background-position: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        @include respond-to('desktop') {
            height: $news-on-homepage__image-height--desk;
        }
        @media #{$medium-down} {
            height: 185px;
        }
    }

    &__link {
        text-decoration: none;
        display: block;
        padding: 2 * $home-page-grid;
        @include respond-to('desktop') {
            padding: 3 * $home-page-grid;
        }
        background-color: white;
        height: 100%;
    }
    &__blurb,
    &__date {
        display: block;
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #000;
        @media #{$hd-up} {
            font-size: 18px;
        }
    }
    &__blurb {
        color: $color-primary;
    }

    &__title {
        margin-top: 1em;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        @media #{$hd-up} {
            font-size: 24px;
        }
    }
}

.swiper-container-news {
    width: 100%;
    max-width: 100%;
    max-height: 100vh;
    // CSS Grid/Flexbox bug size workaround
    // @see https://github.com/kenwheeler/slick/issues/982
    min-height: 0;
    min-width: 0;
    margin-left: -25px;
    @media #{$small-up} {
        margin-left: -45px;
    }
    @media #{$large-up} {
        margin-left: 0;
    }
    // The following 'hack' is a little fragile and relies on all ancestors being
    // in `position: static`.
    &::before {
        content: ' ';
        position: absolute;
        left: 0;
        right: 0;
        background: $color-primary--alt;
        height: $news-on-homepage__image-height;
        @include respond-to('desktop') {
            height: $news-on-homepage__image-height--desk;
        }
    }
}
