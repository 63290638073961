.faq-container,
.planvisit-container {
    @media #{$large-up} {
        margin-top: 100px;
    }

    h4 {
        margin-bottom: grid(0.5);
        &:not(:first-of-type) {
            margin-top: grid(1);
        }
    }

    & > ul {
        list-style: none;
        perspective: 900;
        padding: 0;
        margin: 0;
        display: block;
        & > li {
            position: relative;
            margin-bottom: 30px;
            padding: 30px 70px 30px 30px;
            background: color('White');
            @media #{$hd-up} {
                padding: 60px 115px 60px 115px;
            }

            &:before {
                display: none;
            }
            h2 {
                font-size: 16px;
                line-height: 1.125em;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                -webkit-touch-callout: none;
                user-select: none;
                width: 80%;
                top: 3px;
                position: relative;
                @media #{$medium-up} {
                    font-size: 26px;
                }
                @media #{$hd-up} {
                    font-size: 48px;
                }
            }
            i {
                position: absolute;
                right: 25px;
                background: color('Yellow');
                height: 30px;
                width: 30px;
                top: 25px;
                @media #{$medium-up} {
                    top: 30px;
                    right: 30px;
                }
                @media #{$hd-up} {
                    height: 64px;
                    width: 64px;
                    right: 60px;
                    top: 60px;
                }
                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    background-color: color('White');
                    width: 4px;
                    height: 13px;
                    transition: all 0.2s ease-in-out;
                    top: 9px;
                    left: 13px;
                    @media #{$hd-up} {
                        width: 4px;
                        height: 32px;
                        top: calc(50% - 16px);
                        left: calc(50% - 2px);
                    }
                }
                &.open:before {
                    transform: translate(0) rotate(90deg);
                }
                &:before {
                    transform: translate(0) rotate(0);
                }
                &:after {
                    transform: translate(0) rotate(-90deg);
                }
            }
            .toggle-btn {
                cursor: pointer;
                transition: background-color 0.2s ease-in-out;
                &.open {
                    background-color: color('Azure');
                }
            }
            .faq-content {
                margin: 20px 0 0 0;
                pointer-events: none;
                visibility: hidden;
                max-height: 0;
            }
            input {
                position: absolute;
                top: 0;
                left: 0;
                cursor: pointer;
                width: 100%;
                height: 100%;
                z-index: 1;
                opacity: 0;
                ~ div {
                    position: relative;
                    z-index: 2;
                    max-height: 0;
                    opacity: 0;
                    margin: 20px 0;
                    &.gutenberg-blocks {
                        pointer-events: none;
                        margin: 0;
                        & > .column {
                            padding: 0;
                            @media #{$medium-only} {
                                padding: 0 20px;
                            }
                            .large-12 {
                                width: 100% !important;
                            }
                        }
                        &.open {
                            pointer-events: auto;
                            margin: 20px 0;
                            @media #{$medium-up} {
                                margin: 20px -20px;
                            }
                            @media #{$hd-up} {
                                margin: 40px -20px;
                            }
                            .wp-block-columns:first-of-type {
                                margin-top: 0;
                            }
                        }
                        h3 {
                            text-transform: uppercase;
                            font-size: 12px;
                            color: $color-primary;
                            letter-spacing: 1px;
                            font-weight: 400;
                        }
                    }
                }
            }
            p + ul {
                margin-top: 0;
            }

            a {
                color: black !important;
            }
            @import '../tools/_animations.scss';
            @include animated-a;
        }
    }
}
