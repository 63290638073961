@mixin animated-a($className: 'a', $reverse: false) {
    #{$className}:not(.wp-block-button__link) {
        position: relative;
        display: inline-flex;
        overflow: hidden;
        padding-bottom: 0;
        text-decoration: none;
    }

    #{$className}:not(.wp-block-button__link):hover {
        transition: all 0.35s ease-in-out;
    }

    #{$className}:not(.wp-block-button__link):after {
        content: ' ';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 0.1em solid currentColor;

        transition: all 0.3s ease-in-out;
        transform: translateX(0);
    }

    #{$className}:not(.wp-block-button__link):hover:after {
        transform: translateX(-101%);
    }
    @if $reverse {
        #{$className}:not(.wp-block-button__link):after {
            transform: translateX(-101%);
        }

        #{$className}:not(.wp-block-button__link):hover:after {
            transform: translateX(0);
        }
    }
}

// ----------------------------- Page Reveal animation -----------------------------
@import './easings';

$duration: 1;
$delay: 1;
$stagger: 0.1;
@mixin fade-in-anim {
    animation-name: fade-in;
    animation-duration: #{$duration}s;
    animation-timing-function: $easeOutCubic;
    animation-fill-mode: backwards;
}
@keyframes fade-in {
    0% {
        opacity: 0;
        transform: translateY(60px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
@mixin setup-page-reveal($selector) {
    #{$selector} {
        .content-page-header-container {
            @include fade-in-anim;
            animation-delay: #{$delay}s;
        }

        .row.center:first-child h1 {
            @include fade-in-anim;
            animation-delay: #{$delay}s;
        }

        .subtitle {
            $delay: $delay + $stagger !global;
            @include fade-in-anim;
            animation-delay: #{$delay + $stagger}s;
        }

        &.template-news .has-text-align-center {
            @include fade-in-anim;
            animation-delay: #{$delay + $stagger}s;
        }

        .activity__information {
            $delay: $delay + $stagger !global;

            .column:nth-child(1) {
                @include fade-in-anim;
                animation-delay: #{$delay}s;
            }

            $delay: $delay + $stagger !global;

            .column:nth-child(2) {
                @include fade-in-anim;
                animation-delay: #{$delay}s;
            }

            $delay: $delay + $stagger !global;

            .column:nth-child(3) {
                @include fade-in-anim;
                animation-delay: #{$delay}s;
            }
        }

        $delay: $delay + $stagger !global;

        > .row.width-100.center,
        .large-14 .row.width-100.center {
            @include fade-in-anim;
            animation-delay: #{$delay}s;
        }

        .planvisit-container,
        .news-content {
            $delay: $delay + $stagger !global;
            @include fade-in-anim;
            animation-delay: #{$delay}s;
        }

        &.template-plan-visit:after {
            @include fade-in-anim;
            animation-delay: #{$delay}s;
        }

        &.template-faq .faq-container {
            @include fade-in-anim;
            animation-delay: #{$delay}s;
        }
    }
}

@mixin setup-page-reveal-2($selector) {
    #{$selector} {
        .row.center:first-child .exhibition__date {
            @include fade-in-anim;
            animation-delay: #{$delay}s;
        }

        $delay: $delay + $stagger !global;

        .row.center:nth-child(2) h1 {
            @include fade-in-anim;
            animation-delay: #{$delay}s;
        }

        .exhibition__subtitle {
            $delay: $delay + $stagger !global;
            @include fade-in-anim;
            animation-delay: #{$delay}s;
        }

        .contact__subtitle {
            $delay: $delay + $stagger !global;
            @include fade-in-anim;
            animation-delay: #{$delay}s;
        }

        .activity__information {
            $delay: $delay + $stagger !global;

            .column:nth-child(1) {
                @include fade-in-anim;
                animation-delay: #{$delay}s;
            }

            $delay: $delay + $stagger !global;

            .column:nth-child(2) {
                @include fade-in-anim;
                animation-delay: #{$delay}s;
            }

            $delay: $delay + $stagger !global;

            .column:nth-child(3) {
                @include fade-in-anim;
                animation-delay: #{$delay}s;
            }
        }

        $delay: $delay + $stagger !global;

        .row.width-100.center {
            @include fade-in-anim;
            animation-delay: #{$delay}s;
        }
    }
}

@include setup-page-reveal('.content-page');
@include setup-page-reveal-2('.content-page.template-building');
