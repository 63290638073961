.newsletter {
    margin-bottom: grid(1/1.7);
    width: 80%;
    @include min-(margin-bottom, 1/1.7, 48);

    @media #{$xxlarge-up} {
        text-align: left;
        margin-bottom: 0;
        max-width: 400px;
    }
    @media #{$medium-down} {
        text-align: left;
        margin-bottom: 0;
        width: 100%;
    }

    h3 {
        @media #{$medium-down} {
            font-size: 24px;
            line-height: 1.33;
        }
    }
}

.subscribe-form-footer {
    @media #{$medium-down} {
        text-align: left;
    }

    .subscribe-form-wrap {
        position: relative;
    }

    input[type='email'],
    input[type='text'] {
        color: color('DarkGrey');
        background-color: transparent;
        border: none;
        display: block;
        width: 100%;
        padding: grid(1/2) 0;
        border-bottom: solid 1px color('DarkGrey');
        margin-bottom: 15px;

        @media #{$medium-up} {
            display: inline-block;
            padding: 0 0 grid(1/8) 0;
            border-bottom: solid 1px color('DarkGrey');
            &:first-of-type {
                margin-right: grid(1/4);
            }
        }

        @media #{$medium-down} {
            font-size: 18px;
            &:first-of-type {
                margin-bottom: 15px;
            }
        }

        @media #{$hd-up} {
            font-size: 24px;
        }

        &:hover {
            color: color('Black');
            border-bottom: solid 1px color('White');

            @include placeholder {
                color: color('Black');
            }

            @media #{$medium-up} {
                color: color('White');
                border-bottom: solid 1px color('White');

                @include placeholder {
                    color: color('White');
                }
            }
        }

        &:focus {
            outline: 0;
            color: color('White');
        }
    }

    .error-notice::placeholder {
        color: color('Red') !important;
    }

    .error-notice {
        color: color('Red') !important;
    }

    span {
        position: relative;

        @media #{$medium-up} {
            top: 0;
        }

        &.error-notice,
        &.success-notice,
        &.info-notice {
            display: block;
            float: left;
            top: 10px;
            margin-bottom: 20px;
            height: 60px;
        }

        &.error-notice {
            color: color('Red');
        }

        &.success-notice {
            color: color('White');
        }

        &.info-notice {
            color: color('Yellow');
        }
    }

    @keyframes blink {
        from {
            opacity: 1;
        }
        to {
            opacity: 0.3;
        }
    }
}

.error-message {
    position: absolute;
    padding-left: 3px;
    width: 100%;
    height: auto;
    color: color('Red');
}

.message-wrap {
    height: 100px;
}

.button_subscribe {
    margin-top: 22px;
    margin-bottom: 22px;
    width: 100%;
    height: 48px;
    border-radius: 0px;
    color: color('Black');
    border: 0 none;
    background-color: color('Yellow');
    @include cta-block__background--interactive($color-primary);
    @include min-(font-size, 1/7, 12);
    font-family: 'akzidenzgrotesk-medium-ext', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;

    @media #{$medium-down} {
        margin-bottom: 45px;
        min-width: 100%;
    }

    @media #{$hd-up} {
        font-size: 14px;
    }
}
