#root {
    height: 100%;
    width: 100%;
}

body {
    position: relative;
    background: #f0f0f0;
    overflow-x: hidden;
}

.header-container {
    position: fixed;
    z-index: 4;
    background: color('White');

    .navigation {
        position: absolute;
        left: 0;
        top: 0;
        height: 84px;
        background-color: color('White');
        width: 100vw;

        @media #{$hd-up} {
            height: 128px;
        }
    }
}
.top-notification {
    position: absolute;
    left: 0;
    top: 84px;
    min-height: 70px;
    padding-left: grid(1);
    padding-right: grid(1);
    padding-top: grid(0.5);
    padding-bottom: grid(0.5);
    background-color: #1795d7;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    @media #{$medium-down} {
        font-size: 14px;
        line-height: 1.25em;
        padding-top: 20px;
        padding-bottom: 20px;
        .button.white {
            background-color: #fda729;
            border-color: #fda729;
            padding-left: 20px;
            padding-right: 20px;
            &:hover {
                background-color: #fda729;
            }
            span {
                font-size: 10px;
                color: white;
                white-space: nowrap;
            }
        }
        .top-notification-text {
            width: 50%;
        }
    }
    @media #{$hd-up} {
        top: 128px;
        height: 98px;
    }
    .top-notification-text {
        margin-right: grid(1);
        max-width: 1300px;
    }
}
