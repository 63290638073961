$color-primary: #1795d7;
$color-primary--alt: #f0f0f0;
$color-secondary: color(Purple);
$color-dark: color('Black');
$color-light: color('White');
$color-warning: color('Orange');
$color-error: color('Red');
$color-success: color(Green);
$color-border: color(Offwhite);

$home-page-grid: 12px;
