h1,
h2,
h3,
h4,
h5,
h6 {
    color: #000;
    font-weight: 400;
}

p {
    line-height: 1.33em;
    font-weight: normal;
}

h1 {
    font-weight: normal;
    font-size: grid(1);
    @include min-(font-size, 1, 32);
    @include max-(font-size, 1, 100);
}

h2 {
    font-weight: normal;
    font-size: grid(0.75);
    @include min-(font-size, 0.75, 24);
    @include max-(font-size, 0.75, 80);
}

body {
    line-height: 1.3;
    color: #606060;
    font-weight: 400;
    font-size: 18px;
    @media #{$hd-up} {
        font-size: 24px;
    }
}

ul:not([class]) li::before {
    content: '—';
}
