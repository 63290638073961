:root {
    --divider-color: #1795d7;
}

.ticker-container {
    width: 115%;
    margin-left: -7.5%;

    @media #{$medium-up} {
        width: 148%;
        margin-left: -20%;
    }

    @media #{$large-up} {
        width: 141%;
        margin-left: -18%;
    }
}

.ticker {
    padding: 45px 0;
    @media #{$large-up} {
        padding: 100px 0;
    }
    @media #{$hd-up} {
        padding: 135px 0;
    }
    z-index: 2;
    position: relative;
    overflow: hidden;
    font-family: 'akzidenzgrotesk-medium-ext', sans-serif;
    color: color('Black');

    span {
        font-size: 40px;

        white-space: nowrap;
        padding: 0 40px;
        @media #{$large-up} {
            font-size: 80px;
            padding: 0 50px;
        }
        &::after {
            content: '';
            height: 20px;
            width: 19px;
            background: var(--divider-color);
            position: relative;
            display: inline-block;
            right: -40px;
            .blue-dots & {
                background: #1795d7 !important;
            }
            @media #{$large-up} {
                height: 30px;
                width: 30px;
                top: -10px;
                right: -50px;
            }
        }
    }
}
