$activites__media-height: 234px;

.activities {
    position: relative;

    background: $color-primary;
    box-shadow: inset 34vw 0 0 #f0f0f0;
    @media #{$xxlarge-up} {
        box-shadow: inset 800px 0 0 #f0f0f0;
    }
    @media #{$large-down} {
        box-shadow: inset 44vw 0 0 #f0f0f0;
    }
    @media #{$medium-down} {
        box-shadow: inset 0 (0 - $activites__media-height) 0 #f0f0f0;
        padding-top: 2em;
        margin-bottom: 108px;
    }
    &__content {
        margin-left: 34vw;
        padding: 120px 180px 120px 260px;
        text-align: right;
        color: #fff;
        @media #{$xxlarge-up} {
            margin-left: auto;
            margin-right: 0;
            max-width: 1200px;
        }
        @media #{$large-down} {
            margin-left: 44vw;
            padding: 80px 60px 210px 110px;
        }
        @media #{$medium-down} {
            margin-left: 0;
            padding: 36px;
        }
        @media #{$small-only} {
            padding: 24px;
        }
        &__title {
            color: color('White');
            font-size: 60px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0em;
            text-align: right;
            @media #{$medium-down} {
                font-size: 30px;
            }
            @media #{$hd-up} {
                font-size: 78px
            }
        }
        &__textblock {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.333em;
            letter-spacing: 0em;
            text-align: right;
            @media #{$medium-down} {
                font-size: 18px;
            }
            @media #{$hd-up} {
                font-size: 24px;
            }
        }
        &__link {
            margin-top: 52px;
            a {
                @extend .readmore--interactive;
                padding: 8px;
                text-decoration: none;
                text-transform: uppercase;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0.05em;
                text-align: left;
                font-family: 'akzidenzgrotesk-medium', sans-serif;
                span {
                    vertical-align: middle;
                }
                svg {
                    width: 16px;
                    height: auto;
                    vertical-align: middle;
                    margin-left: 0.5em;
                    @media #{$hd-up} {
                        width: 22px;
                    }
                    rect {
                        fill:  $color-primary;
                        width: 35px;
                        height: 35px;
                        vertical-align: middle;
                    }
                    path {
                        fill:  #fff;
                    }
                }
            }
        }
        &__placeholder {
            height: 470px
        }
        &__textblock2 {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 1.333em;
            letter-spacing: 0em;
            text-align: right;

            @media #{$medium-down} {
                display: none;
            }

            @media #{$hd-up} {
                max-width: 700px;
                margin: 0 auto;
                font-size: 24px;
            }
        }
    }

    &__list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 0;
        overflow-x: hidden;

        @media #{$medium-up} {
            margin-top: -50px;
        }

        @media #{$xlarge-up} {
            padding: 0 36px;
        }
        @media #{$large-down} {
            .column {
                padding: 0 10px;
            }
        }

        & + .activities__content__container {
            @media #{$medium-down} {
                display: none;
            }
        }
    }
}

.activities-item {
    display: flex;
    @extend %cta-block--interactive;
    background-color: white;
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;

    padding-bottom: 315px; //Arbitrary number pulled from design
    white-space: normal;
    overflow: hidden;
    text-decoration: none;
    margin-top: 24px;

    @media #{$xlarge-up} {
        margin-top: 0;
    }

    @media #{$xlarge-down} {
        padding-bottom: 234px;
    }

    @media #{$hd-up} {
        padding-bottom: 90%;
    }

    &__link {
        text-decoration: none;
        display: block;
        padding: 2 * $home-page-grid 2 * $home-page-grid ;
        background-color: white;
        height: 100%;
        width: 100%;
        @media #{$large-up} {
            min-height: 130px;
        }
        @media #{$xlarge-up} {
            min-height: 155px;
        }
        @media #{$hd-up} {
            min-height: 210px;
            padding: 45px 34px 35px 34px
        }
    }
    &__blurb {
        display: block;
        font-size: 12px;
        line-height: 1.25;
        letter-spacing: 0.1em;
        color:  $color-primary;
        text-transform: uppercase;
        @media #{$hd-up} {
            font-size: 18px;
        }
    }
    &__title {
        margin-top: 1em;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.25;
        @media #{$hd-up} {
            margin-top: 0.7em;
            font-size: 24px;
        }
    }
}