.content-page.template-building {
    padding-bottom: 0;
    .exhibition__date {
        text-align: center;
        display: block;
        font-size: 18px;
        margin-bottom: 10px;
        @media #{$hd-up} {
            font-size: 24px;
        }
    }

    h1 {
        @media #{$medium-down} {
            font-size: 32px;
        }
    }

    .exhibition__subtitle {
        margin-bottom: 40px;
        text-align: center;
        font-size: 18px;

        @media #{$large-up} {
            max-width: 650px;
            margin: -3em auto 80px;
            font-size: 18px;
        }

        @media #{$medium-down} {
            margin-top: -1em;
        }
    }

    .column-full {
        padding: 0;
        .video__player {
            padding: 0;
        }
    }

    .full-image {
        position: relative;
        z-index: 1;
        width: 100%;
        height: 100%;
    }

    .ticker {
        span {
            color: color('White');
            &::after {
                background: rgba(255, 255, 255, 0.4);
            }
        }
    }

    .ticker-container .ticker {
        span {
            color: color('Black');
        }
    }

    .page {
        .gutenberg-blocks {
            .gutenberg-blocks {
                padding: grid(3) 0;
                @media #{$large-up} {
                    padding: grid(1) 0;
                }
            }
        }

        .gutenberg-group {
            width: 115%;
            padding: grid(1) 0;
            margin-left: -7.5%;

            @media #{$medium-up} {
                width: 148%;
                margin-left: -20%;
            }

            @media #{$large-up} {
                width: 141%;
                margin-left: -18%;
            }

            &.block-with-plus {
                position: relative;
                z-index: 2;

                &:after {
                    content: '';
                    width: 120px;
                    height: 120px;
                    display: block;
                    position: absolute;
                    bottom: -60px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    background-image: url("data:image/svg+xml,%3Csvg fill='none' height='121' viewBox='0 0 123 121' width='123' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath clip-rule='evenodd' d='m75.9878 0h-27.0137v2.10568h-2.4207v44.24442h-44.13262v2.1056h-2.42078v25.8178h46.5534v46.3505h25.8178v-.91h3.6166v-45.4405h42.5302v-.9098h3.617v-27.0136h-46.1472zm42.5302 72.1679h2.421v-24.622h-46.147v-46.35008h-24.622v.90986h22.2012v46.35002h46.1468zm-71.9646-24.622h-42.93679v.9098h42.93679zm25.8178 70.9721h2.4208v-44.2445h-2.4208z' fill='%23fff' fill-rule='evenodd'/%3E%3C/svg%3E");
                    background-size: contain;
                }
            }
        }
    }

    .content-text {
        background-color: #f0f0f0;
    }

    .previous__exhibitions {
        padding: 80px 0 0;
        background-color: #f0f0f0;
        @media #{$large-up} {
            padding: grid(2) 0 0;
        }
        .wrapper {
            &__inner {
                margin-top: 175px;
                background: $color-primary;
                @media #{$hd-up} {
                    margin-top: 250px;
                }
                .row {
                    top: -70px;
                    @media #{$large-up} {
                        top: -grid(1);
                    }
                    position: relative;
                }
            }
        }
        .exhibition__single {
            padding-bottom: 20px;
            img {
                width: 100%;
                height: 250px;
                object-fit: cover;
                @media #{$medium-up} {
                    height: 420px;
                }
            }
            &__wrapper {
                padding-top: 12px;
                @media #{$medium-down} {
                    width: calc(100% - 48px);
                    margin: auto;
                }
            }
            &__link {
                text-decoration: none;
                display: block;
            }
            &__date {
                color: color('White');
                display: block;
                font-size: 12px;
                line-height: 1.5;
                margin-bottom: 0.3em;
                @media #{$hd-up} {
                    font-size: 18px;
                }
            }
            &__title {
                color: color('White');
                font-size: 24px;
                font-weight: 400;
                line-height: 1.5;
                @media #{$hd-up} {
                    font-size: 30px;
                }
            }
        }
    }

    .inventor_intro {
        padding: 120px 0;
        margin-top: 0;
    }

    .inventor_column {
        padding: 2em 0 !important;
        @media #{$medium-down} {
            padding: 0.5em 0 !important;
        }
        h2 {
            margin-top: 120px;
            @media #{$medium-down} {
                margin-top: 50px;
            }
        }
        .gutenberg-blocks {
            padding: 0 !important;
        }
        &:nth-of-type(2) {
            .wp-block-columns {
                @media #{$medium-down} {
                    flex-direction: column-reverse;
                }
            }
        }
    }

    .inventor_column_last {
        margin-bottom: 120px;
        @media #{$medium-down} {
            margin-bottom: 50px;
        }

        .wp-block-column {
            &:last-child {
                @media #{$medium-down} {
                    margin-bottom: 0;
                }
            }
        }
    }

    .inventor-form-wrap {
        background-color: $color-primary;

        .screen-reader-response {
            height: 0;
        }

        .small-full {
            display: flex;
            justify-content: center;
            padding: 88px 0;

            @media #{$medium-down} {
                padding: 50px 0;
            }
        }
        .wpcf7-form {
            display: flex;
            justify-content: center;

            @media #{$medium-down} {
                width: calc(100% - 48px);
                margin: auto;
            }

            .inventor-form {
                h5 {
                    color: white;
                }
                p {
                    margin-bottom: 0;
                }
                .wpcf7-form-control {
                    width: 50vw;
                    margin-bottom: 8px;
                    border: none;

                    @media #{$medium-down} {
                        width: 100%;
                    }

                    &.error-form::placeholder {
                        color: red;
                        font-size: 0.8rem;
                    }

                    &.wpcf7-text {
                        font-family: 'akzidenzgrotesk-medium-ext';
                        font-style: normal;
                        font-weight: 400;
                        font-size: rem(18px);
                        line-height: 1em;
                        color: #000000;
                        height: auto;
                        padding: 23px 32px;
                    }

                    &.wpcf7-text:focus {
                        outline: none;
                    }

                    &.inventor-submit {
                        box-sizing: border-box;
                        width: 172px;
                        height: 48px;
                        margin-top: 33.5px;
                        background-color: color('Yellow');
                        color: color('White') !important;
                        @include cta-block__background--interactive($color-primary);
                        border: 0;
                        padding: 15px;

                        &:hover,
                        &:focus {
                            color: #fff !important;
                            background-position: 0 0;
                            transition-duration: 0.4s;
                            border: solid 1px #fff;
                        }

                        @media #{$medium-down} {
                            width: 100%;
                            height: 48px;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }

    .has-black-color {
        color: color('Black');
    }

    .inventor-bottom {
        padding: 0 !important;
        .gutenberg-blocks .gutenberg-button-group {
            justify-content: center;
            flex-direction: row;
            div {
                @media #{$medium-down} {
                    width: 100%;
                }
            }
        }

        h2 {
            @media #{$large-up} {
                max-width: 80%;
                margin-left: auto;
                margin-right: auto;
            }
        }

        p {
            @media #{$large-up} {
                max-width: 43%;
                margin-left: auto;
                margin-right: auto;
            }
        }

        .inventor-button {
            display: flex;
            a {
                background-color: color('Yellow') !important;
                color: color('White') !important;
                @include cta-block__background--interactive($color-primary);
                box-sizing: border-box;
                padding: 15px !important;
                width: 172px;
                height: 48px;
                text-align: center;

                &:hover,
                &:focus {
                    color: color('White') !important;
                    background-position: 0 0;
                    transition-duration: 0.4s;
                    border: solid 1px transparent;
                }

                @media #{$medium-down} {
                    width: 100%;
                    height: 48px;
                    padding: 0 !important;
                }
            }
        }
    }
}
.thank-you-wrap .gutenberg-blocks .gutenberg-button-group {
    justify-content: center;
}

.gutenberg-button-group .inventor-button,
.inventor-button {
    display: flex;
    a {
        background-color: color('Yellow') !important;
        color: color('White') !important;
        @include cta-block__background--interactive($color-primary);
        box-sizing: border-box;
        padding: 15px;
        width: 172px;
        text-align: center;
        font-family: 'akzidenzgrotesk-medium-ext' !important;

        &:hover,
        &:focus {
            color: color('White') !important;
            background-position: 0 0;
            transition-duration: 0.4s;
            border: solid 1px transparent;
        }

        @media #{$medium-down} {
            width: 100%;
            height: 48px;
            padding: 0;
        }
    }
}
