.gutenberg-blocks .details {
    > .column {
        padding: 0;
    }

    @media #{$medium-up} {
        .inner-row {
            width: calc(100% + 40px);
            margin-left: -20px;
        }
    }

    @media #{$medium-down} {
        .inner-row {
            width: 100%;
        }
    }

    &__heading {
        margin-top: 20px;
        margin-bottom: 20px;
        font-family: 'akzidenzgrotesk-regular', sans-serif;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: inherit;
        opacity: 0.8;
    }

    &__title {
        display: inline-block;
        width: 120px;
        padding-right: 10px;

        @media #{$medium-down} {
            width: 100%;
        }
    }

    &__line {
        margin-bottom: 10px;

        &:last-of-type {
            margin-bottom: 0;
        }

        @media #{$medium-down} {
            &:first-of-type {
                margin-bottom: 10px;
            }
        }
    }

    &__btn {
        margin-top: 30px;
        margin-bottom: 2em;

        @media #{$medium-down} {
            margin-top: 25px;
        }
    }
}
