header {
    .logo {
        margin-right: grid(1);
        font-size: 0;

        @media #{$medium-down} {
            img {
                width: 105px;
                max-width: 105px;
            }
        }

        @media #{$hd-up} {
            img {
                width: 136px;
            }
        }

        svg g {
            fill: color('Black');
        }
    }

    nav {
        position: fixed;
        z-index: 2;
        top: 0;
        left: 0;
        width: grid(24);
        padding-left: grid(1);
        padding-right: grid(1);
        display: flex;
        align-items: center;
        justify-content: left;
        height: grid(1);
        @include min-(height, 1, 80);
        transition: all 0.3s ease-in-out;

        @media #{$medium-up} {
            height: grid(1.5);
            @include min-(height, 1.5, 120);
        }

        &.header-dark {
            background: color('Black');
        }

        .language-switch {
            margin-top: 0;
            visibility: hidden;
            text-align: right;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 20%;
            min-width: fit-content;
            max-width: 300px;

            @media #{$large-up} {
                visibility: visible;
            }
        }

        ul {
            &.navigation_dropdown_container {
                margin-left: auto;
                flex-shrink: 0;
                height: 100%;
                @media #{$large-up} {
                    display: flex;
                    align-items: center;
                }

                .submenu {
                    margin-right: 30px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    border-top: 6px solid transparent;
                    transition: border 0.3s ease-out;
                    @media #{$xlarge-up} {
                        margin-right: 50px;
                    }

                    &.clicked {
                        text-decoration: underline !important;
                    }
                    ul {
                        padding: grid(1/3) grid(1/2);
                        position: absolute;
                        display: none;
                        animation: fadeIn 0.6s;
                        background: #fff;
                        margin-left: -grid(1/2);
                        top: 60%;

                        li {
                            padding-left: 0 !important;
                            margin-right: 0;
                            margin-top: 0;

                            @media #{$small-up} {
                                &:hover {
                                    color: color('Azure');
                                }
                            }

                            &::before {
                                display: none;
                            }
                        }
                    }
                    .fa-sort-down {
                        transform: translateY(-2px);
                        margin-left: 5px;
                    }

                    &:hover {
                        border-top: 6px solid color('Yellow');

                        .fa-sort-down {
                            transform: translateY(5px) rotate(180deg);
                        }

                        i:before {
                            opacity: 0;
                        }

                        ul {
                            display: block;
                        }
                    }
                }
            }

            li,
            a {
                color: color('Black');
                text-decoration: none;
                font-size: 16px;

                &.active {
                    text-decoration: underline;
                }
            }

            &.language-switch-list {
                li {
                    margin-right: 3px;
                    height: 36px;
                    line-height: 36px;

                    a {
                        color: color('Black');
                        text-decoration: none;
                        font-size: 16px;

                        &:focus,
                        &:hover,
                        &.active {
                            color: color('Yellow');
                        }
                    }

                    #language-symbol {
                        margin: 0px 5px 0 0;
                    }
                }

                &.white {
                    a {
                        color: color('White');

                        &:focus,
                        &:hover,
                        &.active {
                            border-bottom: 1px solid color('White');
                        }
                    }

                    svg path {
                        fill: color('White');
                    }
                }

                .button {
                    height: 36px;
                    width: 151px;
                    border-radius: 0px;
                    color: color('White') !important;
                    border: 0 none;
                    background-color: color('Yellow');
                    @include min-(font-size, 1/7, 12);
                    font-family: 'akzidenzgrotesk-medium-ext', sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    /* identical to box height */
                    text-align: center;
                    text-transform: uppercase;
                    @include cta-block__background--interactive($color-primary);

                    @media #{$hd-up} {
                        width: 152px;
                        height: 50px;
                        padding: 20px 14px 16px 14px;
                        vertical-align: middle;
                    }
                }
            }
        }

        &.white {
            svg g {
                fill: color('White');
            }

            li,
            a {
                color: color('White');
            }
        }
    }

    &.search-active {
        .logo {
            svg g {
                fill: color('Black') !important;
            }
        }

        nav ul li {
            color: color('Black') !important;

            &.submenu i:after,
            &.submenu i:before {
                background-color: color('Black') !important;
                transition: none !important;
            }
        }
    }
}

.wrapper.full {
    header {
        .logo {
            svg g {
                fill: color('White');
            }
        }

        nav ul:not(.language-switch) li {
            color: color('White');

            &.submenu i:before,
            &.submenu i:after {
                background-color: color('White');
            }
        }

        nav {
            & > ul > li {
                & > span > a {
                    line-height: 60px;
                }
                & > i {
                    top: 25px;
                }
            }
            ul ul {
                top: 80px;
                background: rgba(color('Black'), 0.8);
            }

            &.header-dark {
                ul ul {
                    background: color('Black');
                }
            }
        }
    }
    #hamburger-menu {
        button.menu-open,
        button.menu-close {
            svg {
                color: color('White');
            }

            &:before {
                color: color('White');
            }
        }
    }
}

#hamburger-menu {
    .hidden {
        opacity: 0;
        visibility: hidden;
        clip: unset;
    }
    nav {
        position: relative;
        height: 100%;
        width: 100%;
        position: fixed;
        z-index: 3;
        left: 0;
        top: 0;
        background-color: color('Black');
        padding-top: grid(0.35);
        transition: opacity 0.3s ease-out, visibility 0.3s ease-out;
        @include min-(padding-top, 0.35, 22);

        &.hide {
            visibility: hidden;
        }

        .logo {
            margin-left: grid(1);
            display: inline-block;

            @media #{$medium-up} {
                margin-top: 20px;
            }

            svg g {
                fill: color('White');
            }
        }

        #navigation {
            width: 100%;
            height: 100%;
            /* The main point: */
            overflow-y: auto;
            /* Optional but highly reccomended: enables momentum scrolling on iOS */
            -webkit-overflow-scrolling: touch;
            padding: 20px;
            /* Make room for search bar */
            padding-bottom: 120px;
        }

        ul {
            display: block;

            li {
                display: block;
                padding-bottom: grid(1/3.4);
                @include min-(padding-bottom, 1/3.4, 20);

                &::before {
                    display: none;
                }

                &.submenu {
                    color: color('White');
                    text-decoration: none;
                    font-size: 26px;
                    padding-left: 0;

                    span {
                        cursor: pointer;
                    }

                    .fa-sort-down {
                        transform: translateY(-2px);
                        margin-left: 10px;
                        font-size: 14px;
                    }

                    .submenu-wrapper {
                        margin-left: 30px;
                        ul {
                            display: none;
                            animation: fadeIn 0.6s;
                            padding-top: 20px;

                            li {
                                padding-left: 0 !important;
                                padding-bottom: 0;
                                line-height: 1;

                                a {
                                    color: color('White');
                                    text-decoration: none;
                                    font-size: 20px;
                                    line-height: 1.6;
                                    margin-bottom: 8px;
                                    display: block;
                                    max-width: 100%;
                                    width: auto;

                                    &.active {
                                        text-decoration: underline;
                                    }
                                }

                                &::before {
                                    display: none;
                                }
                            }
                        }
                    }

                    &.open {
                        .fa-sort-down {
                            transform: translateY(5px) rotate(180deg);
                        }

                        ul {
                            display: block;
                        }
                    }
                }
            }

            a {
                color: color('White');
                text-decoration: none;
                font-size: grid(1 / 1.7);
                @include min-(font-size, 1 / 1.7, 24);

                &.active {
                    text-decoration: underline;
                }

                &.button {
                    @include min-(padding-bottom, 1/8, 15);
                    margin: grid(0.75) 0;
                }
            }
        }

        .language-switch__mobile {
            margin-bottom: 20px;
            li {
                display: inline-block !important;
                margin-right: 5px;
                color: color('White');
                font-size: 21px;
                a {
                    cursor: pointer;
                    &.active {
                        color: $color-primary;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .buyticketsmobile__button {
        position: absolute;
        z-index: 3;
        left: 0;
        top: auto;
        max-width: unset;
        padding: 0;
        line-height: 48px;
        position: fixed;
    }

    button.menu-open,
    button.menu-close {
        position: fixed;
        top: 30px;
        right: grid(1);
        padding: 0;
        cursor: pointer;
        z-index: 20;
        background: none;
        border: none;

        svg {
            color: color('Black');
            font-size: 26px;
            width: 20px;
            right: 0;
            position: absolute;
        }

        &:before {
            content: 'Menu';
            font-size: 14px;
            color: color('Black');
            text-transform: uppercase;
            position: relative;
            left: -30px;
            top: 5px;
        }
    }
    button.menu-close {
        &:before {
            color: color('White');
        }
        i {
            position: absolute;
            right: 10px;
            top: 0;
            &:before,
            &:after {
                content: '';
                position: absolute;
                background-color: white;
                width: 4px;
                height: 25px;
                transition: all 0.2s ease-in-out;
            }
            &:before {
                transform: translate(0) rotate(45deg);
            }
            &:after {
                transform: translate(0) rotate(-45deg);
            }
        }
    }
}

.wrapper.small {
    nav {
        background: color('White');
    }

    #hamburger-menu {
        position: fixed;
        z-index: 2;
        top: 0;
        right: 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
