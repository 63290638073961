.content-page.template-inside-building,
.content-page.template-building {
    .activity__information {
        padding: 40px 0 60px 0;
        z-index: 3;

        .column {
            margin-bottom: 20px;
            @media #{$large-up} {
                margin-bottom: 0;
            }
        }
        span {
            font-size: 12px;
            line-height: 1.5em;
            letter-spacing: 0.1em;
            color: $color-primary;
            text-transform: uppercase;
            display: block;
            @media #{$hd-up} {
                font-size: 18px;
            }
        }
        p {
            line-height: 1.5em;
        }
        a {
            color: color('Yellow');
            transition: all 0.3s ease-in-out;
            &:not([target='_blank']) {
                text-decoration: none;
            }
            &:hover {
                color: color('Yellow');
            }

            svg {
                width: auto;
                height: 25px;
                margin-right: 1.5em;
                vertical-align: middle;
            }
        }

        @import '../tools/_animations.scss';
        @include animated-a('.activity__a', true);

        .animated-a {
            @include animated-a;
        }

        table {
            line-height: 1.5em;
            td {
                &:first-of-type {
                    width: 60%;
                }
            }
        }
        &--contact {
            margin-bottom: 0.3em;
            a {
                display: block;
                line-height: 1.5em;
            }
        }
    }

    .subtitle {
        margin-bottom: 20px;
        text-align: center;
        font-size: 18px;

        @media #{$large-up} {
            max-width: 650px;
            margin: -2em auto 40px;
            font-size: 18px;
        }

        @media #{$medium-down} {
            margin-top: -1em;
        }
    }

    .content {
        background: $color-primary;
        padding-top: grid(5);
        margin-top: -grid(5);
        color: color('White');
        padding-bottom: grid(1);
        h2,
        h3 {
            color: color('White');
        }
        &--additional {
            p {
                strong,
                b {
                    a {
                        background-color: transparent;
                        @include cta-block__background--interactive(#fda729);
                        border: solid 1px color('Yellow');
                        transition: all 0.2s ease-in-out;
                        color: color('Yellow');
                        text-decoration: none;
                        padding: 10px 20px 12px;
                        &:hover {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

    .introduction {
        text-align: center;
        margin: rem(80px) auto rem(80px);
        @media #{$medium-down} {
            margin: rem(45px) auto rem(45px);
        }
        @media #{$hd-up} {
            margin: rem(90px) auto rem(90px);
        }
        &.no-top-margin {
            margin-top: 0;
        }
        p {
            font-size: 24px;
            line-height: 1.5;
            color: #000;
            @media #{$large-up} {
                font-size: 30px;
                line-height: 1.6;
            }
            @media #{$hd-up} {
                font-size: 42px;
                line-height: 1.3em;
            }
        }
    }
}

.content-page.template-inside-building {
    h1 {
        text-align: left;
        @media #{$medium-up} {
            text-align: center;
        }
    }

    .introduction {
        p {
            color: #fff;
        }
    }

    .center {
        position: relative;
        z-index: 2;
    }

    .content.center {
        z-index: 1;
    }
}

.education__content {
    margin-top: -8vw !important;
}

.education_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.education_title {
    padding: 0 !important;
}

.education_subtitle {
    margin: 30px auto !important;
}

.education_text {
    .row.center:first-child {
        width: 100%;
    }

    &.small-full &.gutenberg-blocks &.medium-full &.center {
        padding: 100px 0;
    }

    .ticker {
        padding-top: 0;
    }

    .column {
        .gutenberg-group {
            @media #{$medium-down} {
                margin-top: -1px;
            }
        }
        .gutenberg-blocks {
            @media #{$medium-down} {
                width: calc(100vw - 48px) !important;
            }
        }
    }
    .edu_text_block {
        padding: 100px 0;
    }
}
.education-block {
    position: relative;

    .edu-animated-image {
        position: absolute !important;
        top: 91.5%;

        &::before {
            background: none !important;
        }
        img {
            @media #{$medium-down} {
                width: 60%;
            }
        }
    }
}

.edu_first_block {
    padding-top: 0 !important;
}
.edu_block {
    width: 110% !important;
    background-color: #fff !important;
    margin: 80px auto !important;
    padding: 0 !important;

    .medium-20 {
        padding: 0 !important;
    }

    .edu_column {
        margin-bottom: 0 !important;
        margin-top: -1em !important;
        &.withimage {
            margin-bottom: 0 !important;
            margin-top: 0 !important;
        }
        &.vertical_center {
            display: flex;
            align-items: center;
        }
    }
    p.has-text-color {
        font-size: 14px;
        letter-spacing: 0.1em;
        margin-bottom: 1em !important;
        margin-top: 3em !important;
    }
    @media #{$medium-down} {
        width: unset !important;
        .gutenberg-blocks {
            padding: 24px !important;
        }
        .row.center {
            width: 100% !important;
        }
    }
}
