@font-face {
    font-family: 'akzidenzgrotesk-medium';
    src: url('../../fonts/AkzidenzGroteskBEMd.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/AkzidenzGroteskBEMd.woff') format('woff'),
        url('../../fonts/AkzidenzGroteskBEMd.woff2') format('woff2'),
        url('../../fonts/AkzidenzGroteskBEMd.ttf') format('truetype'),
        url('../../fonts/AkzidenzGroteskBEMd.svg#AkzidenzGroteskBEMd') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'akzidenzgrotesk-medium-ext';
    src: url('../../fonts/AkzidenzGroteskBEMdEx.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/AkzidenzGroteskBEMdEx.woff') format('woff'),
        url('../../fonts/AkzidenzGroteskBEMdEx.woff2') format('woff2'),
        url('../../fonts/AkzidenzGroteskBEMdEx.ttf') format('truetype'),
        url('../../fonts/AkzidenzGroteskBEMdEx.svg#AkzidenzGroteskBEMdEx') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'akzidenzgrotesk-regular';
    src: url('../../fonts/AkzidenzGroteskBE.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/AkzidenzGroteskBE.woff') format('woff'),
        url('../../fonts/AkzidenzGroteskBE.ttf') format('truetype'),
        url('../../fonts/AkzidenzGroteskBE.svg#AkzidenzGroteskBE') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

body,
strong {
    font-family: 'akzidenzgrotesk-regular', sans-serif;
}

@for $i from 1 through 6 {
    h#{$i} {
        font-family: 'akzidenzgrotesk-medium-ext', sans-serif;
    }
}
