.planvisit-container {
    .faq-inner {
        .gutenberg-blocks {
            visibility: hidden;
            max-height: 0;
            margin: grid(0.5) 0 0 0;
        }

        i {
            position: absolute;
            right: 25px;
            background: color('Yellow');
            height: 30px;
            width: 30px;
            top: 25px;
            @media #{$medium-up} {
                top: 30px;
                right: 30px;
            }
            @media #{$hd-up} {
                height: 64px;
                width: 64px;
                right: 60px;
                top: 60px;
            }
            &:before,
            &:after {
                content: '';
                position: absolute;
                background-color: color('White');
                width: 4px;
                height: 13px;
                transition: all 0.2s ease-in-out;
                top: 9px;
                left: 13px;
                @media #{$hd-up} {
                    width: 4px;
                    height: 32px;
                    top: calc(50% - 16px);
                    left: calc(50% - 2px);
                }
            }
            &.open:before {
                transform: translate(0) rotate(90deg);
            }
            &:before {
                transform: translate(0) rotate(0);
            }
            &:after {
                transform: translate(0) rotate(-90deg);
            }
        }
        @media #{$large-up} {
            &:hover {
                .toggle-btn {
                    background-color: color('Azure');
                }
            }
        }
    }
    .toggle-btn {
        cursor: pointer;
    }
}
