.imagetoggle-wrapper {
    .relative {
        position: relative;
    }

    .innerrow {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        width: 100%;
        max-width: 4096px;
        margin: auto;

        .imagetoggle-container {
            margin-left: 0;
            left: 0;
            padding-left: 0;
            width: 100%;

            .img-container.img-container__left {
                width: 85%;
            }

            .img-container.img-container__right {
                right: -4.5vw;
            }
        }
    }

    .imagetoggle-container {
        position: relative;
        margin: grid(2) 0;
        width: 99.225vw;
        margin-left: -49.59vw;
        left: 50%;
        padding-left: 72px;

        @media #{$medium-up} {
            left: 0;
            margin-left: 0;
            padding-left: 0;
            width: 100%;
        }

        .img-container {
            overflow: hidden;
            cursor: pointer;

            &.img-container__left {
                position: relative;
                width: 77%;
                height: 35vh;
                display: block;

                @media #{$medium-up} {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 50%;
                    height: 40vh;
                }

                img,
                .overlay {
                    object-fit: cover;
                    height: 100%;
                    min-width: 100%;

                    @media #{$medium-up} {
                        min-height: 480px;
                    }

                    @media #{$large-up} {
                        min-height: 640px;
                    }
                }
            }

            &.img-container__right {
                position: absolute;
                right: 0;
                top: 0;
                width: 10vw;
                margin: 0;
                height: 35vh;

                @media #{$medium-up} {
                    right: 0%;
                    width: 87.5%;
                    height: 100%;
                    position: relative;
                    margin-left: 12.5%;
                    left: 0;
                    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
                }

                img,
                .overlay {
                    object-fit: cover;
                    height: 100%;
                    min-width: 100%;

                    @media #{$medium-up} {
                        min-height: 480px;
                    }

                    @media #{$large-up} {
                        min-height: 640px;
                    }
                }
            }

            .img {
                max-width: 100%;
                width: 100%;
                height: 100%;
            }

            .overlay {
                display: block;
                position: absolute;
                left: 100%;
                width: 100%;
                height: 100%;
                top: 0;
                margin-top: 0;

                &.slide-to-the-left {
                    animation: slide-to-the-left 0.5s ease;
                    left: 0%;
                }

                &.slide-to-the-right {
                    animation: slide-to-the-right 0.5s ease;
                    left: 100%;
                }
            }
        }
    }

    .imagetoggle-innerblocks {
        padding: grid(2) 0 grid(3) 0;

        @media #{$large-up} {
            position: absolute;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            padding: 0 0 0 grid(2);
        }

        h2 {
            @include min-(font-size, 0.3, 28);
            @media #{$large-up} {
                @include min-(font-size, 0.5, 40);
            }
        }

        .row.center {
            justify-content: flex-start;
            width: 100%;
            .column {
                width: 100%;
            }
        }

        .gutenberg-button {
            a {
                text-decoration: none;
                background: color('Black');
                color: color('White');
                border: 1px solid color('Black');
                &:hover,
                &:focus {
                    background: color('White');
                    color: color('Black') !important;
                    transition: all 0.2s ease-in-out;
                }
            }
        }
    }
}

@keyframes slide-to-the-left {
    0% {
        left: 100%;
    }
    100% {
        left: 0%;
    }
}

@keyframes slide-to-the-right {
    0% {
        left: 0;
    }
    100% {
        left: 100%;
    }
}
