@mixin placeholder {
    ::-webkit-input-placeholder {@content}
    :-moz-placeholder           {@content}
    ::-moz-placeholder          {@content}
    :-ms-input-placeholder      {@content}
}

// Breakpoints script based on
// https://css-tricks.com/snippets/sass/mixin-manage-breakpoints/
$breakpoints: (
  'palm':  ( max-width:  767px ),  //mobile portrait only
  'hands': ( min-width:  992px ), //mobile landscape, or tablet and up
  'desktop':  ( min-width: 1024px ) //duh...
) !default;

/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {
    // If the key exists in the map
    @if map-has-key($breakpoints, $breakpoint) {
      // Prints a media query based on the value
      @media #{inspect(map-get($breakpoints, $breakpoint))} {
        @content;
      }
    }
   
    // If the key doesn't exist in the map
    @else {
      @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
          + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
  }

// Borrowed from https://gist.github.com/certainlyakey/e9c0d8f5c87ff47e3d5b
@function encodecolor($string) {
  @if type-of($string) == 'color' and str-index(#{$string}, '#') == 1 {
    $hex: str-slice(ie-hex-str($string), 4);
    $string: unquote('#{$hex}');

    @return '%23' + $string;
  }

  @return $string;
}

@mixin cta-block__background--interactive($color) {
  @extend %cta-block__background--interactive;
  $safeColor: encodecolor($color);
  background-image: url(
    'data:image/svg+xml;utf8,' +
    '<svg viewBox="0 0 4 4" xmlns="http://www.w3.org/2000/svg">' +
      '<rect x="0" y="0" width="2" height="4" fill="' + $safeColor + '"/>' +
    '</svg>'
  );
}

@mixin cta-block__background--rinteractive($color) {
  @extend %cta-block__background--rinteractive;
  $safeColor: encodecolor($color);
  background-image: url(
    'data:image/svg+xml;utf8,' +
    '<svg viewBox="0 0 4 4" xmlns="http://www.w3.org/2000/svg">' +
      '<rect x="2" y="0" width="2" height="4" fill="' + $safeColor + '"/>' +
    '</svg>'
  );
}
