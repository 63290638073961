.partner-panels-wrapper {
    .partner-panel {
        padding: 0 grid(1/4);
        .panel-logo {
            padding: grid(1);
            margin: 0;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            @media #{$medium-up} {
                padding: grid(1/4);
                height: 150px;
            }

            @media #{$large-up} {
            }

            img {
                max-width: 100%;
                @media #{$medium-up} {
                    padding: 0 grid(0.5);
                    height: 100%;
                    object-fit: contain;
                }

                @media #{$large-up} {
                    padding: 0 grid(0.125);
                }
            }
        }
    }
}