.cookies-modal {
    padding: grid(1/3);
    @include min-(padding, 1/3, 20);
    background: white;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 100;
    box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);
    .column {
        padding-left: 0;
    }
}

.cookies-modal h2 {
    font-size: 2rem;
    margin-bottom: grid(1/6);
    @include min-(margin-bottom, 1/6, 12);
    line-height: 1;
}

.cookies-modal .modal-content {
    p {
        font-size: 0.9rem;

        @media #{$medium-up} {
            font-size: 1rem;
        }
    }

    .modal-content__buttons {
        display: flex;
        align-items: center;
    }
}

.model-content__form {
    display: flex;
    flex-direction: column;
    margin-top: grid(1/4);
    @include min-(margin-top, 1/4, 15);

    & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    label {
        margin-left: 3px;
        font-weight: bold;
    }
}

.cookies-modal-link {
    margin-top: grid(1/4);
    @include min-(margin-top, 1/4, 15);
    text-decoration: underline;
    color: color('Black');
    margin-left: 20px;
    font-size: small;
    cursor: pointer;
}

.button-modal {
    margin-top: grid(1/4);
    @include min-(margin-top, 1/4, 15);
    padding: 15px;
    display: flex;
    justify-content: center;
    background-color: transparent;
    color: color('White');
    border: 1px solid color('Black');
    @include cta-block__background--rinteractive('Black');
    transition-duration: 0.4s;
    cursor: pointer;
    font-family: 'akzidenzgrotesk-medium-ext', sans-serif;
    font-size: grid(1/7);
    @include min-(font-size, 1/7, 12);
    transition: all 0.2s ease-in-out;
}

.button-modal:hover {
    color: color('Black');
    transition-duration: 0.4s;
}
