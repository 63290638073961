figure {

    &.aligncenter {
        text-align: center;
    }

    &.alignright {
        text-align: right;
    }

}

img {
    max-width: 100%;
    height: auto;
}

.image-full figure img {
    width: 100%;
}