.wrapper {
    position: relative;
    z-index: 2;
}

footer {
    display: block;
    position: relative;
    z-index: 3;
    color: color('DarkGrey');
    background-color: color('Black');

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        position: relative;
        z-index: 10;
    }

    @supports (-ms-accelerator: true) or (-ms-ime-align: auto) {
        position: relative;
        z-index: 10;
    }

    .footer-container {
        z-index: -1;
        padding: grid(1.5) grid(1);
        background-color: color('Black');

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            position: static;
            z-index: 4;
        }

        @supports (-ms-accelerator: true) or (-ms-ime-align: auto) {
            position: static;
            z-index: 4;
        }

        @media #{$large-up} {
            padding: grid(1.5) 0 grid(1/6) 0 !important;
        }

        a {
            text-decoration: none;
            @media #{$medium-up} {
                &:hover {
                    p {
                        color: color('White');
                    }
                }
            }
        }

        h3 {
            color: color('White');
            font-size: 16px;
            @media #{$medium-down} {
                font-size: 18px;
            }
            @media #{$hd-up} {
                font-size: 24px;
            }
            margin-bottom: 1em;
            font-family: 'akzidenzgrotesk-regular', sans-serif;
            & + p,
            & + form {
                margin-top: grid(1/5);
                line-height: 1.5em;
            }
        }

        p {
            color: #747879;
            padding-right: 0;
            line-height: 1.5em;
        }

        .button {
            margin-top: grid(1);
            @media #{$large-up} {
                margin-top: 0;
            }
        }

        .footer-wrap {
            display: flex;
            align-items: center;
            padding-bottom: grid(1);
            justify-content: space-between;
        }

        @media #{$medium-down} {
            .opening-hours {
                margin-top: 2em;
            }

            .footer-wrap {
                display: flex;
                flex-direction: column-reverse;
                align-items: unset;
            }
        }

        .row.center .row.center {
            align-items: center;
            justify-content: center;
            padding-top: grid(0.5);
        }

        .first-row {
            padding-bottom: grid(0.5);

            @media #{$medium-down} {
                padding-bottom: grid(3);
                border-bottom: 1px solid color('DarkGrey');
            }
        }

        table {
            line-height: 1.5em;
            @media #{$medium-down} {
                line-height: 1.5em;
            }
            td {
                padding: 0 10px 0 0;
            }
        }
    }
}

.footer-menu {
    display: inline-block;
    vertical-align: top;
    ul {
        display: flex;
        flex-direction: row;
        padding-top: grid(1);
        width: 100%;
        text-align: center;
        @media #{$large-up} {
            padding-top: 0;
            text-align: left;
        }
        @media #{$medium-down} {
            justify-content: space-between;
        }
        li {
            display: inline-block;
            padding: 0 1.5em 0 0;
            list-style: none;
            font-size: 12px;
            @media #{$large-up} {
                font-size: rem(16px);
            }

            &:before {
                content: '';
            }

            &:last-child {
                padding-right: 0;
            }

            a,
            button {
                background: none;
                -webkit-appearance: none;
                border: none;
                text-decoration: none !important;
                line-height: 1.5;
                color: color('DarkGrey');
                padding: 0;

                &:focus,
                &:hover {
                    color: color('Black');
                    cursor: pointer;
                }

                @media #{$large-up} {
                    &:focus,
                    &:hover {
                        color: color('White');
                    }
                }
            }
        }
    }
    &:last-child {
        padding-right: 0;
        @media #{$large-up} {
            float: right;
            max-width: 700px;
        }
        ul {
            justify-content: space-between;
            @media #{$medium-down} {
                flex-wrap: wrap;
            }
            li:last-child {
                padding-right: 0;
                @media #{$xlarge-up} {
                    float: right;
                }
            }
        }
    }

    .footer-menu-wrap {
        @media #{$medium-down} {
            flex-direction: column;
            align-items: flex-start;
            padding-top: 30px;
            li {
                padding-top: 10px;
            }
            li:first-child {
                color: white;
            }
        }
    }
}
.side-left {
    text-align: left;
    @media #{$medium-down} {
        padding-top: 50px;

        li {
            padding: 0;
        }
    }
}
.side-right {
    text-align: right;
}

.btn-bestel-tickets {
    padding: 12px 14.5px;
    background-color: color('Black');
    font-family: 'akzidenzgrotesk-medium-ext', sans-serif;
    text-align: center;
    color: color('White');
    border: 0;

    @media #{$large-up} {
        background-color: color('White');
        color: color('Black');
    }

    &:hover,
    &:focus {
        color: #0f0f0f !important;
        opacity: 0.7;
    }
}
