%building-hotspot__cta {
    @extend .readmore--interactive;
    padding-top: 8px;
    color: rgb(253, 167, 41);
    text-decoration: none;
    text-transform: uppercase;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.05em;
    text-align: left;
    span {
        vertical-align: middle;
    }
    svg {
        width: 22px;
        height: 16px;
        vertical-align: middle;
        margin-left: 0.4em;
        rect {
            fill: #000;
            width: 25px;
            height: 25px;
        }
        path {
            fill: rgba(253, 167, 41, 1);
        }
    }
}

%building-hotspot__card--top {
    .building-hotspot__card {
        position: absolute;
        left: rem(47px);
        bottom: 0;
        @media #{$hd-up} {
            left: rem(62px);
        }
    }
}

.building-hotspots {
    width: 70vw;
    height: 58vw;
    background-size: contain;
    background-repeat: no-repeat;
    position: relative;
    overflow: visible;
    margin-bottom: 180px;
    margin-top: -200px;

    @media #{$large-down} {
        width: 60vw;
        height: 48vw;
    }

    @media #{$medium-down} {
        margin-top: 0;
        width: 100vw;
        height: 79vw;
    }
    &__cards_container_mobile {
        display: none;
        padding: 24px;
        height: 250px;
        margin-top: -190px;
        position: relative;

        @media #{$medium-down} {
            display: block;
        }
    }
}

.building-hotspot {
    position: absolute;
    display: inline-flex;

    &__icon {
        display: block;
        width: 37px;
        height: 37px;
        overflow: hidden;
        background-color: rgb(253, 167, 41);
        transform-origin: center;
        img {
            width: inherit;
        }

        @media #{$medium-down} {
            width: 30px;
            height: 30px;
        }
        @media #{$hd-up} {
            width: 45px;
            height: 45px;
        }
        @media #{$xxxlarge-up} {
            width: 62px;
            height: 62px;
        }
    }

    &__card {
        display: none;
        height: 380px;
        width: 300px;
        background-color: black;
        color: #fff;
        z-index: 3;
        animation: fadeIn 0.5s;

        @media #{$medium-down} {
            width: 86vw;
            min-height: 144px;
            height: fit-content;
        }

        @media #{$hd-up} {
            width: 450px;
            height: 570px;
            margin-left: 25px;
        }

        .building-hotspot__media {
            height: 250px;
            width: 100%;
            background-size: cover;

            @media #{$medium-down} {
                height: auto;
                min-height: 144px;
            }
            @media #{$hd-up} {
                height: 310px;
            }
        }

        .building-hotspot__content {
            padding: 26px 58px 28px 31px;
            display: flex;
            flex-direction: column;

            @media #{$medium-down} {
                width: 100%;
            }

            @media #{$hd-up} {
                padding: 45px 58px 28px 31px;
            }

            &__title {
                padding-top: 8px;
                color: #fff;

                font-size: 24px;
                @media #{$medium-down} {
                    width: 100%;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: left;
                }

                @media #{$hd-up} {
                    font-size: 32px;
                    line-height: 1.22em;
                }
            }

            &__cta {
                @extend %building-hotspot__cta;
                @media #{$medium-down} {
                    font-size: 12px;
                }
                @media #{$hd-up} {
                    font-size: 24px;
                    padding-top: 1em;
                }
            }

            &__blurb {
                padding-top: 8px;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 1.17em;
                letter-spacing: 0em;
                text-align: left;
                color: rgba($color: #fff, $alpha: 0.4);
                @media #{$medium-down} {
                    font-size: 12px;
                }
                @media #{$hd-up} {
                    font-size: 24px;
                }
            }
        }
    }
    &__card_mobile {
        display: flex;
        flex-direction: row;
        background-color: black;
        color: #fff;
        z-index: 3;
        width: 86vw;
        min-height: 144px;
        height: fit-content;
        margin-top: -3.5em;

        .building-hotspot__media {
            height: auto;
            min-height: 144px;
            width: 100%;
            background-size: cover;
            background-position: center;
        }

        .building-hotspot__content {
            padding: 26px 58px 28px 31px;
            display: flex;
            flex-direction: column;
            width: 100%;

            @media #{$medium-down} {
                padding: 20px 20px 15px 20px;
            }

            &__title {
                color: #fff;
                padding: 8px;
                width: 100%;
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: 0em;
                text-align: left;
                @media #{$medium-down} {
                    padding: 0;
                }
            }

            &__cta {
                @extend %building-hotspot__cta;
                font-size: 12px;
            }

            &__blurb {
                padding: 8px;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0em;
                text-align: left;
                color: rgba($color: #fff, $alpha: 0.4);
                @media #{$medium-down} {
                    padding: 0;
                }
            }
        }
    }

    &:hover {
        max-width: 350px;
        width: 100%;
        width: -moz-available; /* WebKit-based browsers will ignore this. */
        width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
        justify-content: space-between;

        @media #{$hd-up} {
            max-width: 535px;
        }
    }
    &:hover &__card {
        display: flex;
        flex-direction: column;

        @media #{$medium-down} {
            display: none;
        }
    }
}
.active {
    max-width: 350px;
    width: 100%;
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
    justify-content: space-between;
    .building-hotspot__card {
        display: flex;
        flex-direction: column;

        @media #{$medium-down} {
            display: none;
            width: 0;
            max-width: 0;
        }
    }
}

.building-hotspot--bens-garage {
    top: 40%;
    left: 33%;
}

.building-hotspot--move-amsterdam-shop {
    @extend %building-hotspot__card--top;
    top: 53%;
    left: 80%;
}

.building-hotspot--bentley-lamborghini {
    top: 39%;
    left: 74%;
}

.building-hotspot--meeting-center {
    top: 37%;
    left: 30%;
}

.building-hotspot--pon-corporate-center {
    top: 29%;
    left: 57%;
}

.building-hotspot--ride-out {
    top: 21%;
    left: 66%;
    @media #{$medium-down} {
        top: 20%;
        left: 65%;
    }
}

.building-hotspot--restaurant-wils {
    top: 36%;
    left: 89%;
    @media #{$medium-down} {
        top: 37%;
        left: 86%;
    }
}

.building-hotspot--madame-cyclette {
    top: 24%;
    left: 68%;
}

.building-hotspot--cupra-city-store {
    @extend %building-hotspot__card--top;
    top: 68%;
    left: 69%;
    @media #{$medium-down} {
        top: 68%;
        left: 66%;
    }
}

.building-hotspot--porsche {
    @extend %building-hotspot__card--top;
    top: 62%;
    left: 74%;
}

.building-hotspot--event-center {
    top: 26%;
    left: 41%;
}

.building-hotspot--kidsclub {
    top: 50%;
    left: 43%;
    @media #{$medium-down} {
        top: 49%;
        left: 41%;
    }
}

.building-hotspot--mobility-experience {
    @extend %building-hotspot__card--top;
    top: 52%;
    left: 82%;
    @media #{$medium-down} {
        top: 52%;
        left: 79%;
    }
}

.building-hotspot--swapfiets {
    top: 14%;
    left: 58%;
    @media #{$medium-down} {
        top: 11%;
        left: 55%;
    }
}

.building-hotspot--wils-bakery-cafe {
    top: 27%;
    left: 75%;
}
