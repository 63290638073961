.content-page.template-plan-visit {
    h1 {
        padding-bottom: 40px !important;
    }

    &::after {
        content: '';
        position: fixed;
        height: 30%;
        bottom: 0;
        width: 100%;
        background: $color-primary;
        z-index: -1;
    }
}

.planvisit-container .faq-inner {
    scroll-margin: 150px;

    @media #{$medium-down} {
        scroll-margin: 110px;
    }

    .gutenberg-blocks {
        .row {
            @media #{$medium-down} {
                width: 100%;
            }
        }
        .column {
            // Overwrite column width which is used by the regular columns component
            width: 100% !important;

            @media #{$large-up} {
                // Overwrite padding which is used by the regular columns component
                padding: 10px !important;
            }
        }
        li {
            line-height: 1.5em;
            display: list-item;
            list-style-position: outside;
            list-style-type: disc;
            margin-left: 1rem;
            padding-left: 1rem;
            &:before {
                content: none !important;
            }
        }
    }
}
