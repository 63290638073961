.button,
.gutenberg-button a,
.wp-block-button__link,
.inventor-submit {
    background-color: transparent;
    @include cta-block__background--interactive(#fda729);
    border: 1px solid color('Yellow');
    color: color('Yellow') !important;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    font-size: 12px;
    line-height: 1.15;
    display: inline-block;
    padding: 12px;
    text-transform: uppercase;
    letter-spacing: 0;
    font-weight: 400;
    font-family: 'akzidenzgrotesk-medium-ext';

    &.white {
        @include cta-block__background--interactive(#fda729);
        border-color: #fff;
        color: #fff !important;
        &:hover {
            border-color: #fda729;
        }
    }

    @media #{$hd-up} {
        padding: 20px 22px;
    }

    @media #{$medium-down} {
        width: 100%;
        max-width: 345px;
        text-align: center;
        height: 48px;
        padding: 0;
        margin: 0 auto;
        line-height: 48px;
    }
    @media #{$small-only} {
        max-width: unset;
    }

    &:hover,
    &:focus {
        color: #fff !important;
    }

    a {
        text-decoration: none;
    }
}

.gutenberg-button.button-black a,
.wp-block-button__link.has-very-dark-gray-background-color {
    background-color: color('Black');
    border: 1px solid color('Black');
    color: color('White') !important;
    &:hover,
    &:focus {
        background-color: transparent;
        color: color('Black') !important;
    }
}

.swiper-container-header,
.slideshow-content {
    .button:focus,
    .button:hover {
        border-color: color('Yellow');
        background-color: transparent;
        color: color('Yellow');
    }
}

.gutenberg-blocks {
    & > .row .gutenberg-button {
        padding: 0;
        margin: grid(1) 0;
        @media #{$large-up} {
            margin: grid(1/4) 0;
        }
    }

    .button-group {
        padding: 0;
    }

    .gutenberg-button-group {
        display: flex;
        @media #{$medium-down} {
            flex-direction: column;
        }
        .button-center {
            justify-content: center;
        }
    }
    .inline_btns {
        display: flex;
        div {
            &:first-child {
                .is-style-outline {
                    a {
                        margin-left: 0;
                    }
                }
            }
        }
        .is-style-outline a {
            margin-left: 20px;
            @include cta-block__background--interactive(#fda729);
            border-color: #fff;
            @media #{$medium-down} {
                margin-left: 0;
                margin-top: 10px;
            }
            &:hover {
                border-color: #fda729;
            }
        }
    }
}

.buyticketsmobile {
    &__button {
        width: 100%;
        max-width: unset;
        line-height: unset;
        height: unset;
        background: $color-primary;
        border: solid 1px rgba(#fff, 0.2);
        text-align: center;
        text-transform: uppercase;
        font-size: 18px;
        font-family: 'akzidenzgrotesk-medium-ext', sans-serif;
        padding: 16px;
        bottom: 0;
        z-index: 1;
        color: #fff !important;
        &:hover,
        &:focus {
            background: color('Yellow');
            border: solid 1px rgba(#fda729, 0.2);
        }
    }
}

.clamped--bottom {
    position: relative;
    top: 0;
    width: 100%;
    z-index: 3;
    &.sticky {
        position: fixed;
        top: 100vh;
        top: calc(var(--vh, 1vh) * 100 - 54px - env(safe-area-inset-bottom));
    }
}

p a strong,
p strong a,
p a b {
    @extend .button;
    @include cta-block__background--interactive(#fda729);
    color: color('Yellow') !important;
    &:hover,
    &:focus {
        color: #fff !important;
    }
}

p a strong {
    background-color: #fda729;
    @include cta-block__background--interactive(#1795d7);
    color: #fff !important;
    border: none;
}

.content,
.details,
.fill-btn {
    .wp-block-button__link {
        @extend .button;
        @include cta-block__background--rinteractive(#fda729);
        color: #fff !important;

        &:hover,
        &:focus {
            color: #fff !important;
            border: solid 1px #fff;
            background-position: 0 0;
            transition-duration: 0.4s;
        }
    }
}

.details,
.fill-btn {
    .wp-block-button__link {
        &:hover,
        &:focus {
            background-color: #1795d7;
        }
    }
}

.education_btn {
    background-color: #fda729;
    @include cta-block__background--interactive(#1795d7);
    color: #fff !important;
    border: none;
    text-transform: uppercase;
    letter-spacing: 0;
    font-weight: 400;
    cursor: pointer;
    padding: 16px;
    font-family: 'akzidenzgrotesk-medium-ext';
    margin-bottom: 80px;
    animation-name: fade-in;
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-fill-mode: backwards;
    animation-delay: 1.8s;
    line-height: 1rem;
    @media #{$medium-down} {
        width: 100%;
    }
}

.cupra_btns {
    @media #{$xxxlarge-up} {
        position: absolute;
        bottom: 15vh;
    }
    @media #{$xxlarge-up} {
        position: absolute;
        bottom: 13vh;
    }
}
