%practical-info--text {
    font-weight: 400;
    color: #fff;
    line-height: 24px;

    h3 {
        color: color('White');
        letter-spacing: 0em;
        font-size: 30px;
        line-height: 36px;
        line-height: 1.1;
        word-wrap: break-word;
        @media #{$medium-up} {
            font-size: 35px;
        }
        @media #{$xlarge-up} {
            font-size: 45px;
        }
        @media #{$xxlarge-up} {
            font-size: 60px;
        }
        @media #{$hd-up} {
            font-size: 78px;
        }
    }

    * + td {
        padding-left: 0.5em;
    }

    p, table {
        margin: 1em 0 0;
        width: 100%;
        line-height: 1.333em;
    }
}

%practical-info--image-container {
    margin: 2 * $home-page-grid auto 0;

    @media #{$large-up} {
        width: 50%;
        margin: 0;
    }

    img {
        height: auto;
        width: 100%;
    }

}

%practical-info--content-container {
    margin-top: 7 * $home-page-grid;
    display: flex;

    @media #{$large-up} {
        margin-top: 0;
        &[class] { //specificity hack
            flex-flow: row;
        }
    }
    @media #{$medium-down} {
        margin-top: 30px;
    }
}

.practical-info-block {
    display: flex;
    flex-flow: column;
    background: $color-primary;
    padding: 48px 3 * $home-page-grid;
    @media #{$large-up} {
        padding: 180px 3 * $home-page-grid;
    }
    //Following line is a layout hack due to background-color switching in design
    padding-bottom: 7 * $home-page-grid; //This should be margin-top on next element

    &_first {
        @extend %practical-info--content-container;
        flex-flow: column-reverse;

        &_image1_container {
            @extend %practical-info--image-container;
        }

        &_text1 {
            @extend %practical-info--text;

            @media #{$medium-up} {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            @media #{$large-up} {
                padding: 6% 8vw;
                width: 50%;
            }
        }
    }

    &_second {
        @extend %practical-info--content-container;
        flex-flow: column;

        &_image2_container {
            @extend %practical-info--image-container;
            @media #{$large-up} {
                width: 57%;
                position: relative;
                top: -5vw;
                margin-bottom: -5vw;
            }
        }

        &_text2 {
            @extend %practical-info--text;

            @media #{$large-up} {
                width: 43%;
                padding: 6% 8vw 0;
            }

            @media #{$hd-up} {
                width: 50%;
                padding: 0 8vw 0;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

        }
    }
    a {
        font-family: 'akzidenzgrotesk-medium', sans-serif;
        padding: 8px 8px 8px 0;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.05em;
        text-align: left;
        &::after {
            content: '';
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAyMiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoCiAgICAgICAgZmlsbFJ1bGU9ImV2ZW5vZGQiCiAgICAgICAgY2xpcFJ1bGU9ImV2ZW5vZGQiCiAgICAgICAgZD0iTTE3LjI0MTMgNi4yNjUzOUwxMi43MzM4IDEuNzU3ODlMMTQuNDkxNyAyLjM2MTI4ZS0wNkwyMiA3LjUwODMzTDE0LjQ5MTcgMTUuMDE2N0wxMi43MzM4IDEzLjI1ODhMMTcuMjQxMiA4Ljc1MTQxTC0xLjY0MTk0ZS0wNiA4Ljc1MTRMLTkuNTgxMTdlLTA3IDYuMjY1MzhMMTcuMjQxMyA2LjI2NTM5WiIKICAgICAgICBmaWxsPSIjZmZmZmZmIgogICAgLz4KPC9zdmc+");
            background-size: 16px 11px;
            position: relative;
            display: inline-block;
            width: 16px;
            height: 11px;
            background-repeat: no-repeat;
            margin-left: 0.7em;
            @media #{$hd-up} {
                background-size: 22px 15px;
                width: 22px;
                height: 15px;
            }
        }
        &:hover {
            &::after {
                position: relative;
                animation-duration: 0.3s;
                animation-name: bounce-right;
                animation-direction: alternate;
                animation-iteration-count: 4;
            }
        }
    }
}

.building-picture-container {
    box-shadow: inset 0 25vw 1px $color-primary;
    height: 50vw;
    overflow: hidden;
    padding: 0 3 * $home-page-grid;
    img {
        width: 100%;
        height: auto;
    }
}
