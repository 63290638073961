.slideshow-content {
    scroll-snap-stop: always;
    margin-top: grid(1);

    &.hidden-slider {
        display: none;
    }

    &.first-slideshow {
        margin-top: grid(2);
    }

    &.dark-background {
        background-color: color('Black');
        color: color('White');
    }

    .column:last-of-type {
        padding: 0;
        text-align: center;

        h3 {
            @include min-(font-size, 0.6, 32);
            padding-bottom: grid(1);
            line-height: grid(2.5);

            @media #{$medium-up} {
                line-height: 1.5;
                padding-bottom: grid(1/3);
            }
        }

        .row {
            height: 100%;
        }

        .column {
            display: flex;
            flex-direction: column;

            @media #{$medium-up} {
                padding: 0 grid(2) 0 grid(1);
            }

            p {
                text-align: left;
            }

            @media #{$large-up} {
                text-align: left;

                div:last-of-type {
                    p {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.swiper-container-slideshow {
    overflow: hidden;
    height: 80vh;
    scroll-snap-stop: always;
    position: relative;

    &.fixed .swiper-slide {
        height: 100vh !important;
        transition: height 0.5s ease-out;

        img {
            height: 100vh !important;
            top: 0 !important;
        }
    }

    .swiper-slide {
        text-align: center;

        h2 {
            font-size: grid(1);
            @include min-(font-size, 0.9, 34);
            text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
            padding: 0 grid(3);

            @media #{$medium-up} {
                @include min-(font-size, 1.5, 96);
            }

            @media #{$large-up} {
                font-size: grid(0.75);
            }
        }

        img {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        & > div {
            position: relative;
            display: flex;
            z-index: 2;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            color: color('White');
            align-items: center;
        }
    }
}

.swiper-container-vertical {
    & > .swiper-pagination-bullets {
        right: grid(1/2) !important;
    }

    .swiper-pagination-bullet {
        width: 32px;
        height: 32px;
        line-height: 28px;
        background: color('Black');
        color: color('White');
        border: 1px solid color('White');
        opacity: 1;
        border-radius: 0;
        font-weight: 900;
        margin: 10px 0 !important;
        transition: background-color 0.3s ease;
    }

    .swiper-pagination-bullet:focus,
    .swiper-pagination-bullet:hover,
    .swiper-pagination-bullet-active {
        background: color('White');
        color: color('Black');
        border: 1px solid color('Black');
    }
}
