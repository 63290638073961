.swiper-container-slideshow,
.swiper-container-header,
#slide-description-container {
    .description {
        margin: 0;
        color: color('White');
        font-weight: 700;
        line-height: 1.44;
        text-align: center;
        width: 100%;
        font-size: grid(1/6);
        @include min-(font-size, 1/6, 14);

        @media #{$large-up} {
            text-align: left;
            width: auto;
            font-size: grid(1/5);
            @include min-(font-size, 1/5, 16);
        }

        span {
            font-weight: normal;
        }
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 10;

    &.display-block {
        bottom: 0;
        opacity: 1;
        visibility: visible;
        transition: all 0.3s ease-in;
    }

    &.display-none {
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in;
    }

    .modal-wrapper {
        position: relative;
        background: color('White');
        width: 80vw;
        height: 60vh;
        top: calc(50%);
        left: 50%;
        transform: translate(-50%, -50%);

        @media #{$medium-up} {
            height: 70vh;
            width: 60vw;
            top: calc(50% + 60px);
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            bottom: -grid(4.25);
            height: 50px;
            background: linear-gradient(rgba(255, 255, 255, 0), white);
            pointer-events: none;
            @media (orientation: landscape) {
                bottom: -38px;
            }
            @media #{$medium-up} {
                height: 100px;
                bottom: 0;
            }
        }
    }

    .modal-container {
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        background: color('White');
        height: 70vh;

        .modal-header {
            position: relative;
            height: 20vh;

            @media #{$large-up} {
                height: 35vh;
            }

            &::after {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
                background-image: linear-gradient(113deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0));
            }

            .modal-close {
                position: absolute;
                @include min-(font-size, 0.3, 28);
                color: color('White');
                z-index: 10;
                top: 2vh;
                left: 4vw;
                transition: all 1s ease-in-out;
                @media #{$large-up} {
                    top: 4vh;
                    left: 3vw;
                }
                &:hover {
                    cursor: pointer;
                    color: color('Black');
                }
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: relative;
            }
        }

        .modal-content {
            padding: 4vh 6vw;
            @media #{$large-up} {
                padding: 8vh 3vw;
            }

            h3 {
                @include min-(font-size, 0.3, 28);
                @media #{$large-up} {
                    @include min-(font-size, 0.4, 32);
                }
            }

            .button {
                margin: grid(1) 0;
                @media #{$large-up} {
                    float: right;
                    margin: 0;
                }
            }
        }
    }
}

.swiper-container-slideshow .swiper-slide {
    height: 100% !important;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        height: 200px;
        background: linear-gradient(rgba(255, 255, 255, 0), black);
    }

    .row {
        padding: 0 grid(1);
    }

    .slide-description-pop {
        position: absolute;
        bottom: 0;
        left: 0;
        align-items: center;
        width: 100%;
        @include min-(font-size, 0.225, 18);

        @media #{$medium-up} {
            bottom: grid(1);
        }

        .description {
            text-align: center;
            @media #{$medium-up} {
                text-align: left;
            }
        }

        .button {
            margin-bottom: 0;
            &.button-white {
                background: color('White');
                border: 1px solid color('White');
                color: color('Black');
                &:hover,
                &:focus {
                    background-color: color(Azure);
                    border: 1px solid color(Azure);
                    color: color('White') !important;
                }
            }
            @media #{$medium-down} {
                background: color('White');
                border: color('White');
                color: color('Black');
                padding: grid(0.6) grid(1.2);
            }
        }
    }
    .column:last-of-type {
        text-align: center !important;
        margin-top: 20px;
        @media #{$medium-up} {
            text-align: right !important;
            margin-top: 0;
        }
    }
}
