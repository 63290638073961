.content-page.template-mobility {
    padding-bottom: 0;
    padding-top: 0 !important;

    .ticker-container .ticker {
        span {
            color: color('Black');

            &:after {
                background-color: #db281c;
            }
        }
    }

    .page {
        .gutenberg-blocks {
            .gutenberg-blocks {
                padding: grid(3) 0;
                @media #{$large-up} {
                    padding: grid(1) 0;
                }
            }

            h2 {
                margin-top: 60px;
                font-size: 60px;
                line-height: 66px;

                @media #{$medium-down} {
                    margin-top: 40px;
                    font-size: 30px;
                    line-height: 33px;
                }
            }

            p {
                color: color('Black');
            }
        }

        .gutenberg-group {
            width: 115%;
            padding: grid(1) 0;
            margin-left: -7.5%;

            @media #{$medium-up} {
                width: 148%;
                margin-left: -20%;
            }

            @media #{$large-up} {
                width: 141%;
                margin-left: -18%;
            }

            @media #{$medium-down} {
                &.reverse-block {
                    .wp-block-column {
                        &:first-of-type {
                            order: 2;
                        }

                        &:last-of-type {
                            order: 1;
                        }
                    }
                }
            }

            &.no-padding {
                padding: 0;

                .gutenberg-blocks {
                    padding: 0;
                }

                & + .gutenberg-group {
                    padding-top: 0;

                    .gutenberg-blocks {
                        padding: 0;
                    }
                }
            }
        }
    }

    .gutenberg-blocks a.wp-block-button__link,
    .mobility__btn {
        background-color: color('Yellow');
        color: color('White') !important;
        @include cta-block__background--interactive($color-primary);
        border: 0;

        &:hover,
        &:focus {
            color: #fff !important;
            background-position: 0 0;
            transition-duration: 0.4s;
        }
    }
}

.wp-block-button__link.mobility__btn,
.mobility__btn .wp-block-button__link {
    background-color: color('Yellow');
    color: color('White') !important;
    @include cta-block__background--interactive($color-primary);
    border: 0;
    max-width: 345px;
    @media #{$medium-down} {
        padding: unset;
    }

    &:hover,
    &:focus {
        color: #fff !important;
        background-position: 0 0;
        transition-duration: 0.4s;
    }
}

.mobility__btn.inverted .wp-block-button__link {
    &:hover,
    &:focus {
        color: #fff !important;
        background: color('Blue');
        border: 1px solid #fff;
        background-position: 0 0;
        transition-duration: 0.4s;
    }
}

#language-switch-header {
    .mobility__btn {
        background-color: color('Yellow');
        color: color('White') !important;
        @include cta-block__background--interactive($color-primary);
        border: 0;
        padding: 15px;

        &:hover,
        &:focus {
            color: #fff !important;
            background-position: 0 0;
            transition-duration: 0.4s;
        }
    }
}

.mobility {
    &__header {
        width: 100%;
        height: 100vh;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__logo {
        max-width: 870px;
        max-height: 130px;

        @media #{$medium-down} {
            max-width: 300px;
        }
    }

    &__heading {
        max-width: 650px;
        margin: 30px auto 70px;
        font-size: 44px;
        line-height: 55px;
        color: color('Orange');

        @media #{$medium-down} {
            font-size: 24px;
            line-height: 31px;
        }
    }

    &__desc {
        margin: 35px 0;
    }

    &__videos {
        position: relative;
        background-color: color('White');
        padding-bottom: 170px;

        @media #{$medium-down} {
            padding-bottom: 120px;

            h2 {
                width: calc(100% - 150px);
            }
        }

        > div {
            position: relative;
            z-index: 2;
        }

        &:after {
            content: '';
            width: 100%;
            height: 50%;
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            background: color(Azure);
        }
    }

    &__narrow-text {
        width: 80%;

        @media #{$medium-down} {
            width: 100%;
        }
    }
}

.swiper-container-mobility {
    overflow: hidden;
    margin-top: 60px;

    .slider_content_navigation {
        position: absolute;
        right: 0;
        top: -120px;
        left: auto;
        bottom: auto;

        @media #{$medium-down} {
            width: 100%;
            justify-content: flex-end;
        }
    }
}

.video-slide {
    .column {
        padding: 0;
    }

    @media #{$medium-down} {
        .row {
            width: 100%;
        }
    }

    &__title {
        font-family: 'akzidenzgrotesk-medium-ext', sans-serif;
        font-size: 24px;
        line-height: 30px;
        color: color('White');
    }
}

.lottie {
    text-align: left;

    div,
    svg {
        width: auto !important;
        height: 120px !important;

        @media #{$medium-down} {
            height: 80px !important;
        }

        @media #{$hd-up} {
            height: 150px !important;
        }
    }
}

.animated-image {
    position: relative;

    img {
        width: 80%;
        margin: 70px 0 0 30px;
        position: relative;
        z-index: 2;

        @media #{$medium-down} {
            width: 70%;
        }
    }

    &:before {
        content: '';
        width: 70%;
        height: 70%;
        display: block;
        background: color('Black');
        position: absolute;
        top: -10px;
        left: -30px;
    }
}

.show-anim {
    .animated-image {
        img {
            margin: 20px 30px 0 50px;
            transition: all 1s ease-in-out;
            transition-delay: 0.5s;
        }

        &:before {
            top: -10px;
            left: 20px;
            transition: all 1s ease-in-out;
            transition-delay: 0.5s;

            @media #{$medium-down} {
                top: 0;
                left: 30px;
            }
        }
    }
}

.reverse-block {
    .animated-image {
        text-align: right;

        img {
            margin: 70px 30px 0 0;
        }

        &:before {
            left: auto;
            right: -30px;

            @media #{$medium-down} {
                right: -20px;
            }
        }
    }

    .show-anim {
        .animated-image {
            img {
                margin: 20px 50px 0 30px;
            }

            &:before {
                left: auto;
                right: 20px;

                @media #{$medium-down} {
                    top: 0;
                    right: 30px;
                }
            }
        }
    }
}

.vimeo-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;
}

.vimeo-wrapper iframe,
.vimeo-wrapper .vimeo-iframe {
    width: 100vw;
    height: 56.25vw;
    min-height: 100vh;
    min-width: 177.77vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.vimeo-content .vimeo-content-video {
    @media #{$medium-down} {
        min-height: unset;
    }
}

.fast__header {
    position: relative;
    height: calc(64.9341vw);
}

.player-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.content-player {
    aspect-ratio: 364/240;
}

.fast_mobility {
    width: 100% !important;
    margin: 0 !important;
}

.mobility__subtitle__wrap {
    background-color: rgb(255, 255, 254);
    padding: 40px 0;

    .small-full {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.exhibition__subtitle {
    text-align: center;
    font-size: 18px;

    @media #{$large-up} {
        max-width: 650px;
        font-size: 18px;
    }
}

.anim_group {
    position: relative;
}

.anim_title {
    margin-top: 0 !important;
    @media #{$medium-down} {
        margin-bottom: 0 !important;
    }
}

.anim_column {
    position: relative;
    @media #{$medium-down} {
        margin-bottom: 0 !important;
    }
}

.timo {
    position: absolute;
    left: 7%;
    top: 55%;
    width: 30%;
    height: auto;
    z-index: 1;
    max-width: 400px;
    @media #{$hd-up} {
        top: 47%;
    }
    @media #{$medium-down} {
        position: unset;
        width: 80%;
        margin: 70px auto -50px -17px;
        max-width: 350px;
    }
}
