figure {
    iframe {
        width: 100%;
        max-width: 100%;
    }
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%;

    .react-player {
        position: absolute;
        top: 0;
        left: 0;
    }
}