.swiper-blocks {
    background: color('White');
    z-index: 3;
    position: relative;
}

.swiper-container {
    width: 100vw;

    &.dark-background {
        background-color: color('Black');

        .swiper-slide {
            img {
                object-fit: cover;
                height: 100%;
            }

            & > div {
                color: color('White');
            }
        }
    }

    a {
        text-decoration: none;
    }

    .swiper-slide {
        width: 90vw;
        height: 50vh;

        @media #{$medium-up} {
            margin-top: 48px;
            margin-bottom: 48px;
        }

        @media #{$medium-up} {
            width: 50vw;
        }

        img {
            position: absolute;
            display: block;
            width: 100%;
            height: calc(100% - 30px);
            object-fit: contain;
        }

        .description span {
            line-height: 1.44;
            font-size: grid(1/4);
            font-weight: 700;
            @include min-(font-size, 1/4, 18);

            span {
                font-weight: normal;
            }
        }

        & > div {
            position: relative;
            display: flex;
            z-index: 2;
            flex-direction: column;
            height: 100%;
            justify-content: flex-end;
            color: color('Black');

            div {
                padding: 0 16px grid(1/2.5);
            }
        }
    }
}
