.search-toggle {
    margin-top: 0;
    .fa-search {
        top: 2px;
        position: relative;
        height: grid(1/5);
        @include min-(height, 1/5, 16);
    }

    &:hover {
        cursor: pointer;
    }
}

#search-icon {
    display: flex;
    align-items: center;
}

// Default search styling
.search-container {
    opacity: 0;

    @media #{$medium-down} {
        opacity: 1;
    }

    &.hide {
        visibility: hidden;
    }

    .search-inner {
        opacity: 0;
        position: relative;
        text-align: center;

        @media #{$medium-down} {
            opacity: 1;
        }

        .search-fields {
            opacity: 0;
            transform: translate3d(0, 30px, 0);
            margin: 0 auto;
            align-self: flex-end;
            margin-bottom: grid(1);

            @media #{$medium-down} {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }

            form {
                display: flex;
                align-items: baseline;
                justify-content: center;

                input {
                    box-sizing: border-box;
                    outline: none;
                    border: none;
                    border-bottom: solid 1px color('Black');
                    color: color('Black');
                    font-size: grid(1/4);
                    @include min-(font-size, 1/4, 20);
                    width: grid(7);
                    padding: grid(1/10) 0;
                    margin-bottom: grid(1/2);
                    border-radius: 0;
                }

                button {
                    outline: none;
                    border: none;
                    box-sizing: border-box;
                    -webkit-appearance: none;
                    background: none;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

// Desktop menu search styling
.search-container-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: -1;
    .search-inner {
        background: color('White');
        width: 100vw;
        height: grid(5);
        display: flex;
        justify-content: center;
    }
}

// Hamburger menu search styling
.search-container-hamburger {
    background: #4c4c4c;
    padding: 20px 15px;
    width: 100%;
    .search-inner {
        .search-fields {
            margin-bottom: 0;
            form {
                button {
                    .search-icon {
                        color: color('White');
                        position: relative;
                        font-size: grid(1/5);
                        @include min-(font-size, 1/5, 16);
                        top: -1px;
                    }
                }
                input {
                    background: #4c4c4c;
                    width: grid(17);
                    border-bottom: none;
                    color: color('White');
                    margin-left: grid(1);
                    margin-bottom: 0;
                    &::placeholder {
                        /* Firefox, Chrome, Opera */
                        color: color('White');
                    }

                    &:-ms-input-placeholder {
                        /* Internet Explorer 10-11 */
                        color: color('White');
                    }

                    &::-ms-input-placeholder {
                        /* Microsoft Edge */
                        color: color('White');
                    }
                }
            }
        }
    }
}

// Resultpage search styling
.search-container-page {
    .row {
        margin: 0 auto;
        display: block;
        .column {
            .search-inner {
                width: 100%;

                .search-fields {
                    form {
                        width: 100%;
                        display: block;
                        background: white;
                        max-width: 732px;
                        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                        margin: auto;

                        .form-inner {
                            width: 100%;
                            display: flex;
                            align-items: center;

                            .search-submit {
                                margin: 30px 25px 24px;

                                svg {
                                    width: 17px;
                                    height: 17px;
                                }
                            }

                            input {
                                width: grid(14);
                                border: none;
                                margin: 0;
                                width: 100%;
                                height: 64px;
                                font-size: rem(24px);
                                background: none;

                                @media #{$medium-up} {
                                    width: grid(10);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// Resultpage styling
.search-page {
    .number-results {
        font-size: grid(2);
        text-align: center;
        font-family: 'akzidenzgrotesk-medium-ext', sans-serif;
        margin: 0;
        line-height: 1;
        &-description {
            text-align: center;
            p {
                font-size: grid(1/4);
                @include min-(font-size, 1/4, 20);
            }
        }
    }
}

.search-results {
    opacity: 1;
    transition: all 0.3s ease-in-out;
    @media #{$medium-down} {
        margin-top: rem(40px);
    }
    .post-content {
        margin-top: rem(80px);
        @media #{$medium-down} {
            margin-top: rem(40px);
        }
    }
    .post-content .post-element {
        img {
            width: 100%;
        }
        .img-placeholder {
            height: grid(16);
            background: color(PlaceholderGrey);

            @media #{$medium-up} {
                height: grid(9);
            }

            @media #{$large-up} {
                height: grid(4.35);
            }
        }
        .post-excerpt {
            margin-bottom: grid(2);

            @media #{$medium-up} {
                margin-bottom: grid(1);
            }
        }
    }
}

.loading {
    opacity: 0.2;
}

.search-exit svg {
    font-size: 20px;
    top: 4px;
    position: relative;
    align-self: flex-end;
}
