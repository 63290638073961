.wp-block-columns {
    display: flex;
    width: 100%;
    margin: grid(0.5) 0;
    flex-direction: column;

    @media #{$large-up} {
        flex-direction: row;
    }
}

.wp-block-column {
    flex: 1 1 100%;
    margin-bottom: 40px;

    @media #{$large-up} {
        flex: 1;
        margin: 0 grid(0.5);

        &:first-of-type {
            margin-left: 0;
        }

        &:last-of-type {
            margin-right: 0;
        }
    }
}

.gutenberg-blocks.row {
    @media #{$medium-up} {
        margin: 0 -20px;
    }
}
.column {
    @media #{$medium-up} {
        padding: 0 20px;
    }
}

.column.no-padding {
    @media #{$medium-up} {
        padding: 0;
    }
}