.gutenberg-blocks {
    h1 {
        margin-bottom: grid(1/2);
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: grid(1/1.25);
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.1;
        @media #{$large-up} {
            margin-bottom: grid(1/2.5);
        }
    }

    h2 {
        @include min-(font-size, 0.3, 28);
        @media #{$large-up} {
            @include min-(font-size, 0.75, 60);
        }
        @media #{$hd-up} {
            @include min-(font-size, 0.75, 78);
        }
    }

    h3 {
        font-size: 60px;
    }

    h6.font-regular {
        font-family: 'akzidenzgrotesk-regular', sans-serif;
        letter-spacing: 0.2em;
    }

    p,
    td {
        margin-bottom: grid(1.5);
        line-height: 1.333em;
        @media #{$large-up} {
            margin-bottom: grid(1/2.5);
        }
    }

    ol,
    ul,
    table {
        margin-bottom: grid(1.5);
        @media #{$large-up} {
            margin-bottom: grid(1/2.5);
        }
    }

    table {
        tr:nth-child(odd) {
            background-color: #f3f4f5;
        }
        tr td {
            padding: 5px 10px;
        }
    }

    .has-white-color {
        color: #fff !important;
    }

    ol,
    ul {
        list-style: none;
        display: block;
        li {
            line-height: 1.5em;
            display: list-item;
            list-style-position: outside;
            list-style-type: disc;
            margin-left: 1rem;
            padding-left: 1rem;
            &:before {
                content: none !important;
            }
        }
    }    
}

.has-medium-font-size {
    font-size: grid(1/5);
    @include min-(font-size, 1/5, 20);
}

.aligncenter,
.has-text-align-center {
    text-align: center;
}
