.imagecta-wrapper {
    padding: grid(2) 0;
    .relative {
        position: relative;
    }

    .innerrow {
        width: 100%;
    }

    .imagecta-container {
        position: relative;

        .img-container {
            .img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1);

                @media #{$large-up} {
                    width: grid(8);
                    height: grid(8);
                }
            }
        }
    }

    .imagecta-innerblocks {
        padding: grid(1) 0;

        @media #{$large-up} {
            position: absolute;
            top: 50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            padding: 0 0 0 grid(2);
        }

        h2 {
            @include min-(font-size, 0.3, 28);
            @media #{$large-up} {
                @include min-(font-size, 0.5, 40);
            }
        }

        .row.center {
            justify-content: flex-start;
            width: 100%;
            .column {
                width: 100%;
            }
        }

        .button {
            background: color('Black');
            color: color('White');
            border: 1px solid color('Black');
            a {
                text-decoration: none;
            }
            &:hover,
            &:focus {
                background: color('White');
                color: color('Black');
                transition: all 0.2s ease-in-out;
            }
        }
    }
}
