.error-page {
    text-align: center;

    h1 {
        text-align: center !important;
    }
}

#error-page-btn {
    background-color: color('Black');
    border: 1px solid color('Black');
    padding: grid(1/5);
    color: color('White');
    font-family: 'akzidenzgrotesk-medium-ext', sans-serif;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    font-size: grid(1/7);
    @include min-(font-size, 1/7, 12);
    margin-top: 20px;
    display: inline-block;

    &:hover,
    &:focus {
        background-color: transparent;
        color: color('Black');
        border: 1px solid color('Black');
    }
}
