@import '../tools/_animations.scss';

.content-page {
    // padding-top: 200px;
    z-index: 2;
    position: relative;
    min-height: 100vh;
    padding-bottom: grid(3);
    @media #{$large-up} {
        padding-bottom: grid(1);
    }
    @media #{$medium-down} {
        padding-top: 110px;
    }

    h1 {
        font-size: 36px;
        line-height: 1.13;
        padding-bottom: 40px;
        letter-spacing: normal;
        text-align: center;
        @media #{$medium-up} {
            padding-bottom: 80px;
        }
        @media #{$large-up} {
            font-size: 70px;
        }
        @media #{$xlarge-up} {
            font-size: 90px;
        }
        @media #{$hd-up} {
            font-size: 120px;
            line-height: 1.06em;
        }
    }

    // TODO: need to be more precise with classes to not to mess with other links
    // @import '../tools/_animations.scss';
    // @include animated-a;

    h4.full {
        margin-bottom: 0;

        @media #{$medium-down} {
            margin-bottom: 15px;
            text-align: center;
        }
    }

    h4.m-top {
        margin-top: 50px;

        @media #{$medium-down} {
            margin-top: 0;
        }
    }

    .column-full {
        padding: 0;
    }

    p.partners-desc {
        font-size: 18px;
        line-height: 30px;
        opacity: 0.6;

        @media #{$medium-down} {
            max-width: 300px;
            margin: auto;
        }
    }

    .partners-logo {
        &--smt {
            @media #{$medium-down} {
                margin-top: -25px;
            }
        }

        img {
            @media #{$large-down} {
                height: 80px;
            }
        }
    }

    .contact-group {
        margin-bottom: 0;

        @media #{$medium-down} {
            text-align: center;
        }

        h6 {
            margin-bottom: 5px;
        }

        p {
            margin-bottom: 0;
            line-height: 30px;
            color: #000;
        }

        @include animated-a('a', true);

        a {
            text-decoration: none;
        }
    }
}

.stretched-image {
    margin-top: grid(1);
    padding: 0 !important;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        @media #{$large-up} {
            height: 100vh;
        }
    }
}

.content-page .row {
    width: calc(100vw - 48px);

    @media #{$medium-up} {
        width: 100%;
    }
}
