%swiper__nav-button {
    @extend .desktop--interactive;
    height: 54px;
    width: 54px;
    background-color: color('Yellow');
    @include cta-block__background--interactive($color-primary);
    align-items: center;
    justify-content: center;
    display: flex;
    overflow: hidden;
    position: relative;
    svg {
        position: relative;
        path {
            fill: color('White');
        }
    }
}

.slider-block {
    position: relative;

    .swiper-container-homepage {
        width: 100vw;
        height: 100vh;
        z-index: 2;
        overflow: hidden;
        @media #{$medium-down} {
            height: 86vh;
        }

        .swiper-slide {
            img {
                position: absolute;
                object-fit: cover;
                height: 100%;
                width: 100%;
                object-position: center;
            }

            & > div {
                color: color('White');
            }
        }
    }

    .slider_content_navigation {
        position: absolute;
        left: 0px;
        bottom: 0px;
        display: flex;
        z-index: 1;
        justify-content: flex-start;
        align-items: flex-end;

        @media #{$medium-down} {
            width: 100vw;
            max-width: 500px;
        }

        .swiper-button {
            outline: none;
            @extend %swiper__nav-button;
        }

        .custom-swiper-button-prev {
            transform: rotate(-180deg);
        }

        .rectangle {
            position: relative;
            background-color: #fff;
            box-shadow: inset 0 6px 0 rgba(253, 167, 41, 0.3);
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: auto;
            @media #{$large-down} {
                width: 44vw;
                min-height: 280px;
            }
            @media #{$xlarge-up} {
                width: 34vw;
                min-height: 282px;
            }
            @media #{$xxlarge-up} {
                width: 800px;
            }
            @media #{$hd-up} {
                box-shadow: inset 0 16px 0 rgba(253, 167, 41, 0.3);
                min-height: 435px;
            }
            @media #{$medium-down} {
                min-height: 220px;
                width: 267px;
            }

            &_content {
                padding: 0 111px 0 66px;

                @media #{$medium-down} {
                    padding: 0 25px 0;
                }

                @media #{$large-up} {
                    padding: 0 111px 0 66px;
                }

                @media #{$hd-up} {
                    padding: 0 111px 0 66px;
                }

                &-caption {
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.33em;
                    letter-spacing: 0.1em;
                    text-align: left;
                    color: rgba(0, 0, 0, 0.8);
                    text-transform: uppercase;
                    margin-top: 0;

                    @media #{$hd-up} {
                        font-size: 18px;
                    }
                }

                &-title {
                    margin-top: 9px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 36px;
                    line-height: 1.15em;
                    color: #000000;
                    margin-top: 24px;
                    @media #{$medium-down} {
                        margin-top: 16px;
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 30px;
                        letter-spacing: 0em;
                        text-align: left;
                    }
                    @media #{$hd-up} {
                        font-size: 66px;
                    }
                }

                &-link {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0.05em;
                    text-align: left;
                    text-transform: uppercase;
                    color: rgba(253, 167, 41, 1);
                    margin-top: 32px;
                    a {
                        @extend .readmore--interactive;
                        text-decoration: none;
                    }
                    span {
                        vertical-align: middle;
                        margin-right: 0.4em;
                        font-family: 'akzidenzgrotesk-medium', sans-serif;
                    }
                    svg {
                        vertical-align: middle;
                        @media #{$medium-down} {
                            width: 12px;
                            height: auto;
                        }
                        path {
                            fill: rgba(253, 167, 41, 1);
                        }
                    }

                    @media #{$medium-down} {
                        margin-top: 24px;
                        font-size: 12px;
                        line-height: 14px;
                    }

                    @media #{$hd-up} {
                        font-size: 24px;
                    }
                }
            }
        }

        .rectangle.active1 {
            &::before {
                content: '';
                box-shadow: inset 0 6px 0 rgb(253, 167, 41);
                width: 50%;
                position: absolute;
                height: 6px;
                top: 0;
                left: 50%;

                @media #{$hd-up} {
                    box-shadow: inset 0 16px 0 rgb(253, 167, 41);
                    height: 16px;
                }
            }
        }

        .rectangle.active0 {
            @extend .active1;
            &::before {
                left: 0;
            }
        }
    }
}
