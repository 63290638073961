.template-news {
    h1 {
        padding-bottom: 40px !important;
    }
    .news_header {
        padding-bottom: grid(1/2);
    }
}

.news-content {
    @media #{$medium-down} {
        width: 100% !important;
        padding-top: grid(2);
    }
    @media #{$medium-up} {
        box-shadow: inset 0 420px 0 $color-primary;
    }

    &-header {
        padding: grid(3) 0;
        @media #{$medium-up} {
            padding: grid(1) 0;
        }
        p {
            color: color('White');
            font-size: 30px;
            font-style: normal;
            font-weight: 400;
            line-height: 48px;
            letter-spacing: 0em;
            text-align: center;
            @media #{$hd-up} {
                font-size: 36px;
            }
        }
        @media #{$medium-down} {
            width: 100% !important;
            background-color: $color-primary;
            padding-bottom: 0;
        }
    }

    &-first-row {
        @media #{$medium-down} {
            width: 100% !important;
            background-color: $color-primary;
            padding: 0 24px 24px;
            color: color('White') !important;
            h6 {
                color: color('White') !important;
            }
        }
    }

    &-second-row {
        @media #{$medium-up} {
            margin: grid(2) 0 0;
        }
    }

    .news-element {
        overflow: hidden;
        @media #{$medium-down} {
            margin-top: grid(3);
        }
        a {
            overflow: hidden;
            display: block;
            text-decoration: none;
            .post-title {
                text-decoration: none;
                font-family: 'akzidenzgrotesk-medium-ext', sans-serif;
                font-size: 24px;
                font-style: normal;
                font-weight: 400;
                line-height: 1.3;
                letter-spacing: 0em;
                text-align: left;
                margin-top: grid(0.1);
                @media #{$medium-up} {
                    font-size: 1.8vw;
                }
            }
            img {
                object-fit: cover;
                width: 100%;
                height: 250px;
                @media #{$medium-up} {
                    height: 420px;
                }
                @media #{$hd-up} {
                    height: 500px;
                }
            }
        }

        .post-date {
            font-family: 'akzidenzgrotesk', sans-serif;
            text-transform: uppercase;
            display: block;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.1em;
            text-align: left;
            margin: grid(0.5) 0 grid(0.2);
            @media #{$hd-up} {
                font-size: 18px;
                margin: 20px 0 10px 0;
            }
        }

        .post-excerpt {
            margin-bottom: grid(1);
            @media #{$large-up} {
                margin-bottom: grid(1/2);
            }
        }

        .post-excerpt-link {
            margin-bottom: grid(2);
            display: inline-block;
        }
    }
}

.content-page.single-post {
    .content-page-header-container {
        @media #{$medium-up} {
            margin-left: 10rem;
        }
        .title-container,
        .introduction-container {
            margin-bottom: 3rem;
        }
    }
    h1 {
        text-align: left !important;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.15em;
        letter-spacing: 0em;
        padding-bottom: 0;

        @media #{$hd-up} {
            font-size: 42px;
        }
    }

    .subheading {
        margin-bottom: 1.5em;
        font-size: 16px;
        line-height: 1.375em;
        font-weight: 400;
        @media #{$hd-up} {
            font-size: 24px;
        }
    }

    .introduction {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25em;
        letter-spacing: 0em;
        text-align: left;
        @media #{$hd-up} {
            font-size: 24px;
        }
    }
    .stretched-image {
        margin-bottom: 4rem;
    }
    .button-black {
        margin-top: grid(1/4);
    }
    .post-sidebar {
        @media #{$medium-down} {
            padding-bottom: 30px;
        }
        p {
            &:not(:first-of-type) {
                margin-top: 1rem;
            }
            @media #{$medium-up} {
                padding-bottom: 16px;
            }
        }
        p strong {
            color: $color-primary;
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 0.1em;
            line-height: 1.333em;
            font-weight: 400;
            @media #{$hd-up} {
                font-size: 18px;
            }
        }

        .socials {
            @media #{$medium-up} {
                margin-top: 60px;
            }
            svg {
                width: 17px;
                height: 17px;
                margin-right: 10px;
                margin-top: 10px;
                path {
                    fill: rgba(253, 167, 41, 1);
                }
                @media #{$hd-up} {
                    margin-top: 0.8em;
                    width: auto;
                    height: 27px;
                    margin-right: 1.5em;
                }
            }
        }

        .button.button-black {
            text-decoration: none;
            background: #0f0f0f;
            color: #fff;
            border: 1px solid #0f0f0f;
            margin: grid(1) 0;
            @media #{$large-up} {
                margin: grid(1/4) 0;
            }
            &:hover,
            &:focus {
                background: color('White');
                color: color('Black') !important;
                transition: all 0.2s ease-in-out;
            }
        }
    }
}
