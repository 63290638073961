.sidebar__building {
    line-height: 1.5;

    h6 > p {
        font-size: grid(1/5);
        @include min-(font-size, 1/5, 20);
        margin-bottom: grid(1 / 3.3);
        @include min-(margin-bottom, 1 / 3.3, 24);
    }

    .opening-hours {
        line-height: 1.5;
        margin-bottom: grid(1 / 5);
        @include min-(margin-bottom, 1 / 5, 16);
    }

    .button {
        background-color: color('Black');
        border-color: color('Black');
        margin-top: grid(1/2);

        &:focus,
        &:hover {
            color: color('Black') !important;
        }
    }

    .socials {
        margin-top: grid(1.5);
        margin-bottom: grid(1);
        width: 100%;
        div {
            display: flex;
        }
        @media #{$medium-up} {
            margin-top: grid(0.4);
            margin-bottom: 0;
        }
        a {
            width: grid(2);
            @media #{$medium-up} {
                width: grid(1);
            }
            @media #{$xlarge-up} {
                width: grid(0.5);
            }
            svg {
                font-size: grid(1/4);
                @include min-(font-size, 1/4, 24);
                margin-right: grid(0.2);
                @include min-(margin-right, 0.2, 10);
                &:last-of-type {
                    margin-right: 0;
                }
                path {
                    transition: all 0.3s ease-in-out;
                }
            }
        }
        .facebook:hover svg path {
            fill: #3b5998;
        }
        .instagram:hover svg path {
            fill: #c32aa3;
        }
        .youtube:hover svg path {
            fill: #ff0000;
        }
        .twitter:hover svg path {
            fill: #1da1f2;
        }
    }
}
